import React, { useMemo } from 'react';
import { AlgoliaContext } from '@platform/hooks';
import { getEnv } from '../utils/helpers';
import { useAuth } from '@platform/auth';

const AlgoliaProvider: React.FC = (props) => {
  const { user } = useAuth();
  const indexes = useMemo(
    () => ({
      tasks: {
        index: getEnv('algoliaTaskIndex'),
        appId: getEnv('algoliaAppId'),
        apiKey: user?.apiKey,
      },
      profiles: {
        index: getEnv('algoliaProfileIndex'),
        appId: getEnv('algoliaAppId'),
        apiKey: user?.apiKey,
      },
      products: {
        index: getEnv('algoliaProductIndex'),
        appId: getEnv('algoliaAppId'),
        apiKey: getEnv('algoliaProductApiKey'),
      },
    }),
    [user],
  );

  return <AlgoliaContext.Provider value={indexes}>{props.children}</AlgoliaContext.Provider>;
};

export default AlgoliaProvider;
