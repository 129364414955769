import { Palette, ThemeOptions, createTheme } from '@mui/material';
import { DefaultPaletteOptions, colorPalette, isBrowser, setTailwindColors } from '@platform/ui';

type EmployeeCustomColors = {
  appBar: string;
  appBarText?: string;
  svgPrimary: string;
  light?: string;
};

// Add employee customs
export const palette: DefaultPaletteOptions<EmployeeCustomColors> = {
  ...colorPalette,
  custom: {
    ...colorPalette.custom,
    appBar: colorPalette.primary!.main,
    svgPrimary: colorPalette.secondary!.main,
    light: '#D2CDCE',
  },
};

export const themeConfig: ThemeOptions = {
  palette,
  typography: {
    fontFamily: 'Lendis',
    h1: {
      fontSize: '6rem', // 96px
      lineHeight: 1.167, // 112.03px
      fontWeight: 300,
      letterSpacing: '-0.09375rem', // -1.5px
    },
    h2: {
      fontSize: '3.75rem', // 60px
      lineHeight: 1.2, // 72px
      fontWeight: 300,
      letterSpacing: '-0.03125rem', // -0.5px
    },
    h3: {
      fontSize: '3rem', // 48px
      lineHeight: 1.167, // 56.02px
      fontWeight: 400,
    },
    h4: {
      fontSize: '2.125rem', // 34px
      lineHeight: 1.235, // 41.99px
      fontWeight: 400,
      letterSpacing: '0.015625rem', // 0.25px
    },
    h5: {
      fontSize: '1.5rem', // 24px
      lineHeight: 1.334, // 32.02px
      fontWeight: 700,
    },
    h6: {
      fontSize: '1.25rem', // 20px
      lineHeight: 1.6, // 32px
      fontWeight: 700,
      letterSpacing: '0.009375rem', // 0.15px
    },
    subtitle1: {
      fontSize: '1rem', // 16px
      lineHeight: 1.75, // 28px
      fontWeight: 700,
      letterSpacing: '0.009375rem', // 0.15px
    },
    subtitle2: {
      fontSize: '0.875rem', // 14px
      lineHeight: 1.57, // 21.98px
      fontWeight: 500,
      letterSpacing: '0.00625rem', // .1px
    },
    body1: {
      fontSize: '1rem', // 16px
      lineHeight: 1.5, // 24px
      fontWeight: 400,
      letterSpacing: '0.009375rem', // 0.15px
    },
    body2: {
      fontSize: '0.875rem', // 14px
      lineHeight: 1.43, // 20.02px
      fontWeight: 400,
      letterSpacing: '0.010625rem', // 0.17px
    },
    caption: {
      fontSize: '0.75rem', // 12px
      lineHeight: 1.66, // 19.92px
      fontWeight: 400,
      letterSpacing: '0.010625rem', // 0.17px
      display: 'block',
    },
    overline: {
      fontSize: '0.75rem', // 12px
      lineHeight: 2.66, // 31.92px
      fontWeight: 400,
      letterSpacing: '0.0625rem', // 1px
      textTransform: 'uppercase',
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 9999,
          padding: '.35rem 1rem',
          textTransform: 'none',
        },
        sizeSmall: {
          padding: '.25rem .75rem',
        },
        sizeLarge: {
          padding: '.5rem 1.375rem',
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: ({ ownerState }) =>
          ownerState.variant === 'outlined'
            ? {
                position: 'relative',
                borderRadius: 12,
                border: 'none',
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  inset: 0,
                  borderRadius: 'inherit',
                  boxShadow: 'inset 0 0 0 1px',
                  color: 'rgba(0 0 0 / 0.23)', // Set box-shadow color
                  pointerEvents: 'none',
                },
              }
            : { borderRadius: 12 },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.custom.appBar,
          color: theme.palette.custom.appBarText,
        }),
      },
    },
  },
};

// Set MUI and Tailwind Lendis theme
const theme = createTheme(themeConfig);

// Set custom theme values based on the created theme values
theme.palette.custom.appBarText = theme.palette.primary.contrastText;

if (isBrowser) {
  setTailwindColors(theme);
}

export const darkTheme = createTheme({
  ...themeConfig,
  palette: {
    secondary: theme.palette.secondary,
    primary: {
      main: 'rgba(255, 255, 255, 0.7)',
    },
    mode: 'dark',
  },
});

declare module '@mui/material' {
  interface Theme {
    palette: DefaultPaletteOptions<EmployeeCustomColors> & Palette;
  }
}

export default theme;
