import { Typography } from '@mui/material';
import { UserManagementScreensStyles } from '../../UserManagementScreens.styles';
import { TFunction } from 'react-i18next';
import FooterContent from '../AuthFooterContent/FooterContent';
import React, { FC, SVGProps } from 'react';

const ForgotPasswordSuccess = ({
  ForgotPasswordImage,
  t,
}: {
  ForgotPasswordImage: FC<SVGProps<SVGSVGElement>>;
  t: TFunction;
}) => {
  const { classes } = UserManagementScreensStyles();
  return (
    <section className={classes.container}>
      <div className={classes.wrap}>
        <div className={classes.Welcome}>
          <Typography variant="h4">{t('forgot-password.success.heading')}</Typography>
        </div>
        <ForgotPasswordImage className="fill-secondary-main w-80" />
        <FooterContent
          headerText={t('forgot-password.success.helper-test-heading')}
          helperText={t('forgot-password.success.helper-text')}
          buttonText={t('global.contact-support')}
          buttonClick={() => {
            window.location.href = 'mailto:support@lendis.de';
          }}
        />
      </div>
    </section>
  );
};

export default ForgotPasswordSuccess;
