import { Box } from '@mui/material';
import { styled } from '@mui/system';
export const Communicator = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: '0.25rem',
  flexDirection: 'column',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: 10,
  padding: '5px 0px 10px 0',
  maxWidth: theme.breakpoints.values.sm,
  margin: 'auto',
}));
