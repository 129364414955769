import { APIClient } from '@platform/api';
import { getAuthToken } from '@platform/auth';
import { getEnv } from '../utils/helpers';
import mockedAPIs from './mockedAPIs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const API = new APIClient(
  {
    quote: getEnv('reactAppBaseURL'),
    tax: getEnv('reactAppBaseURL'),
    company: getEnv('reactAppBaseURL'),
    userOrder: getEnv('reactAppBaseURL'),
    setting: getEnv('reactAppBaseURL'),
    profile: getEnv('reactAppMicroServiceBaseUrl'),
    task: getEnv('reactAppMicroServiceBaseUrl'),
    order: getEnv('reactAppOrderServiceBaseUrl'),
    employee: getEnv('reactAppMicroServiceBaseUrl'),
    supportTicket: getEnv('reactAppBaseURL'),
    customerSatisfaction: getEnv('reactAppBaseURL'),
  },
  getAuthToken,
);

export default mockedAPIs;
