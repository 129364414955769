import React from 'react';
import { IconButton, Slide, Toolbar, Typography } from '@mui/material';
import { useOverlayView } from '@platform/hooks';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const OverlayView = () => {
  const { state, closeOverlayView } = useOverlayView();

  return (
    <Slide direction="up" in={state.in} mountOnEnter unmountOnExit>
      <div className="fixed inset-0 w-full h-screen overflow-hidden z-[1101] bg-white text-primary-contrastText flex flex-col items-center justify-start">
        <Toolbar
          sx={{
            boxShadow: 'none',
          }}
          className="bg-primary-main w-full justify-center"
        >
          {state.showBackButton && (
            <IconButton
              className="fixed left-0 px-8"
              color="inherit"
              aria-label="go back"
              data-testid="overlayview-back"
              onClick={closeOverlayView}
              edge="start"
            >
              {' '}
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
          )}
          <Typography variant="h6">{state.title}</Typography>
        </Toolbar>
        <div className="flex w-full grow overflow-auto">{state.content ?? null}</div>
      </div>
    </Slide>
  );
};
