import { makeStyles } from 'tss-react/mui';
export const useCardStyles = makeStyles()((theme) => ({
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  selectedCardWrapper: {
    '&:before': {
      color: `${theme.palette.secondary.main} !important`, // Set box-shadow color
    },
  },
  cardMedia: {
    width: '100%',
    height: 150,
    flexGrow: 0,
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  priceBox: {
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'bottom',
    paddingBottom: 6,
  },

  price: {
    fontSize: '1rem !important',
    lineHeight: '1.5rem !important',
    fontWeight: '500 !important',
  },
  cardActions: {
    padding: '0!important',
    marginBottom: '1rem',
    justifyContent: 'center',
  },
}));
