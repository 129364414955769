import { Box, Typography } from '@mui/material';
import { useTranslation } from '@platform/hooks';
import CustomerSupportContacts from './CustomerSupportContacts';

const ContactSupportDrawer = () => {
  const { t } = useTranslation();

  return (
    <Box className="flex flex-col gap-3 pt-5">
      <Typography variant="body2">{t('customer-support.contact-description')}</Typography>
      <Typography variant="subtitle1" className="text-base font-bold">
        {t('customer-support.contact-list-title')}
      </Typography>
      <CustomerSupportContacts supportAgentEmail="logistics@lendis.de" />
    </Box>
  );
};

export default ContactSupportDrawer;
