import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconContainerProps,
  Rating,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from '@platform/hooks';
import { useState } from 'react';

const customIcons: {
  [index: number]: {
    icon: React.ReactElement;
    color: string;
  };
} = {
  1: {
    icon: <SentimentVeryDissatisfied fontSize="inherit" />,
    color: 'text-error-main',
  },
  2: {
    icon: <SentimentDissatisfied fontSize="inherit" />,
    color: 'text-error-main',
  },
  3: {
    icon: <SentimentSatisfied fontSize="inherit" />,
    color: 'text-warning-main',
  },
  4: {
    icon: <SentimentSatisfiedAlt fontSize="inherit" />,
    color: 'text-success-main',
  },
  5: {
    icon: <SentimentVerySatisfied fontSize="inherit" />,
    color: 'text-success-main',
  },
};

const IconContainer = (props: IconContainerProps) => {
  const { value, className, ...other } = props;

  // Only set color when icon is not empty
  let classes = className;
  if (!className?.includes('iconEmpty')) {
    classes += ` ${customIcons[value].color}`;
  }

  return (
    <Box className={classes} {...other}>
      {customIcons[value].icon}
    </Box>
  );
};

type CustomerSatisfactionProps = {
  onHideModal: () => void;
  onSubmit: (rating: number, note?: string) => void;
};

export const CustomerSatisfaction: React.FC<CustomerSatisfactionProps> = ({
  onHideModal,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState<number>(0);
  const [comment, setComment] = useState('');

  const displayError = rating > 0 && rating < 3 && !comment.trim();

  const submitCustomerSatisfaction = () => {
    if (!displayError) {
      onSubmit(rating, comment);
    }
  };

  return (
    <Box className="flex flex-col gap-4">
      <Box>
        <Typography variant="subtitle1">{t('customer-satisfaction.rating-subtitle')}</Typography>
        <Box className="flex gap-4 items-center">
          <Rating
            IconContainerComponent={IconContainer}
            getLabelText={(value) => t(`customer-satisfaction.captions.${value}`)}
            onChange={(_, value) => setRating(value || 0)}
            onChangeActive={(_, value) => {
              setHover(value);
            }}
            highlightSelectedOnly
            size="large"
          />

          {(rating > 0 || hover > 0) && (
            <Typography variant="subtitle2">
              {t(`customer-satisfaction.captions.${hover > 0 ? hover : rating}`)}
            </Typography>
          )}
        </Box>
      </Box>
      <Divider className="w-full" />
      <Box>
        <Typography variant="subtitle1">{t('customer-satisfaction.text-subtitle')}</Typography>
        <TextField
          error={displayError}
          className="w-full"
          label={t('customer-satisfaction.text-comment')}
          multiline
          rows={4}
          variant="filled"
          helperText={displayError && t('customer-satisfaction.comment-error')}
          onChange={(e) => setComment(e.target.value)}
        />
      </Box>
      <Box className="flex justify-end gap-4">
        <Button variant="outlined" color="primary" onClick={onHideModal}>
          {t('global.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={submitCustomerSatisfaction}
          disabled={rating === 0}
        >
          {t('global.submit')}
        </Button>
      </Box>
    </Box>
  );
};
