import { Box, Link, Typography } from '@mui/material';
import { useTranslation } from '@platform/hooks';

interface Props {
  supportAgentEmail: string;
}
const CustomerSupportContacts: React.FC<Props> = ({ supportAgentEmail }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography variant="body2">{t('customer-support.contact-support-agent')}</Typography>
      <Box className="flex flex-col">
        <Link
          href={`mailto:${supportAgentEmail}`}
          variant="body2"
          className="text-inherit no-underline font-bold w-fit"
        >
          {supportAgentEmail}
        </Link>
      </Box>
    </Box>
  );
};

export default CustomerSupportContacts;
