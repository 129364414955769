import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { LOSProduct } from '@lendis-tech/sdk';

export type ProductDetailsData = {
  name: string;
  variantsCount?: string;
  description: string;
  images: LOSProduct['images'];
};

export type ProductDetailsProps = {
  attributes: LOSProduct['details'];
  attributesText: string;
  backgroundColor?: string;
  Buttons?: JSX.Element[];
  buttonsPositionProfile?: 'mobile' | 'modal';
  Images: ReactElement;
  price?: number | string;
  PriceUnderline?: React.FC<any>;
  product: ProductDetailsData;
};

const buttonsPositionProfileClassesMap = {
  mobile: 'fixed text-center',
  modal: 'flex justify-between',
};

const priceInfo = (price: number | string, PriceUnderline: React.FC<any>) => (
  <Box className="px-4">
    <Typography variant="body1">{price}</Typography>
    <PriceUnderline className="w-14 block fill-secondary-main" />
  </Box>
);

export const ProductDetails: React.FC<ProductDetailsProps> = ({
  attributes,
  attributesText,
  backgroundColor,
  Buttons,
  buttonsPositionProfile,
  Images,
  price,
  PriceUnderline,
  product,
}) => {
  const hasAttributes = !!Object.keys(attributes ?? {}).length;

  return (
    <Box className={`grow ${backgroundColor ?? ''}`}>
      <Box className="bg-white px-4">
        {Images}
        <Typography className="py-4" variant="h6">
          {product.name}
          {product.variantsCount && (
            <>
              {' '}
              <span className="font-normal whitespace-nowrap">{product.variantsCount}</span>
            </>
          )}
        </Typography>
      </Box>
      <Box className={`grow ${backgroundColor ?? ''}`}>
        {price && PriceUnderline && priceInfo(price, PriceUnderline)}
        <Typography variant="body2" className="p-4">
          {product.description}
        </Typography>
        {hasAttributes && (
          <Box className="px-4 pb-16">
            <Typography variant="subtitle1">{attributesText}</Typography>

            {Object.values(attributes ?? {}).map((attribute) => {
              return (
                <Typography variant="body2" key={`attribute-${attribute.label}`}>
                  <b>{attribute.label}</b> {attribute.data}
                </Typography>
              );
            })}
          </Box>
        )}
        {Buttons && buttonsPositionProfile && (
          <Box
            className={`w-full bottom-4 ${
              buttonsPositionProfileClassesMap[buttonsPositionProfile] ?? ''
            }`}
          >
            {Buttons}
          </Box>
        )}
      </Box>
    </Box>
  );
};
