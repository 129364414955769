import React, { FormEventHandler, useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import { UserInfo, useAuth } from '@platform/auth';
import { Box, Button, Link, TextField, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmail from 'validator/lib/isEmail';
import { UserManagementScreensStyles } from '../../UserManagementScreens.styles';
import { TFunction } from 'react-i18next';
import FooterContent from '../AuthFooterContent/FooterContent';
import { SocialSignOn } from '../SocialSignOn/SocialSignOn';
import { EnterpriseSignOn } from '../EnterpriseSignOn/EnterpriseSignOn';

export const Login = ({
  navigate,
  t,
  accessType,
  postLoginCallback,
}: {
  navigate: NavigateFunction;
  t: TFunction<'translation', undefined>;
  accessType: 'hasEmployeeAccess' | 'hasAdminAccess';
  postLoginCallback?: () => void;
}) => {
  const { classes } = UserManagementScreensStyles();
  const { lendisOsSso } = useFlags();

  const auth = useAuth();
  const [error, setError] = useState({
    message: '',
    error: false,
  });
  const [userData, setUserData] = useState<UserInfo & { challengeName?: string }>({
    firstname: '',
    lastname: '',
    apiKey: '',
    username: '',
    attributes: { 'custom:customer_id': '' },
    hasAdminAccess: false,
    hasEmployeeAccess: false,
    id: undefined,
    userId: undefined,
  });

  const [loading, setLoading] = useState(false);
  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const formData = new FormData(form);
    const email: string = formData.get('email') as string;
    const password: string = formData.get('password') as string;

    if (!isEmail(email)) {
      setError({
        error: true,
        message: t('global.invalid-email-format'),
      });
      return;
    }

    try {
      setLoading(true);
      const response = await auth.login({ email, password, accessType });
      if (response === undefined) {
        setError({
          error: true,
          message: t('global.error-occurred'),
        });
        return;
      }
      postLoginCallback && postLoginCallback();
      setUserData(response);
    } catch (err) {
      setError({
        error: true,
        message: t('login.no-match-error'),
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth && !auth.isAuthorised) {
      setError({
        message: t('login.no-access-error'),
        error: true,
      });
    }

    if (auth?.isAuthenticated && userData.challengeName === 'NEW_PASSWORD_REQUIRED') {
      navigate('/setup-password');
    } else {
      if (auth?.isAuthenticated && auth.user[accessType]) {
        navigate('/');
      } else if (auth?.isAuthenticated && !auth.user[accessType]) {
        setError({
          message: t('login.no-access-error'),
          error: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [accessType, auth?.user, auth?.isAuthenticated, auth?.isAuthorised, userData, navigate, t]);
  return (
    <section className={classes.container}>
      <div className={classes.wrap}>
        <div className={classes.Welcome}>
          <Typography variant="h4">{t('login.heading')}</Typography>
        </div>
        <div className={classes.Error}>
          <Typography variant="subtitle2">{error.message}</Typography>
        </div>
        <Box
          onSubmit={onSubmit}
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1 },
          }}
        >
          <div className={classes.InputsContainer}>
            <TextField
              label={<>{t('global.email')}</>}
              type="text"
              variant="filled"
              error={error.error}
              name="email"
              placeholder="Email"
              disabled={loading}
            />

            <TextField
              label={<>{t('setup-password.password')}</>}
              type="password"
              error={error.error}
              variant="filled"
              name="password"
              placeholder="password"
              disabled={loading}
            />
          </div>
          <div>
            <div className={classes.ButtonContainer}>
              <Button
                disabled={loading}
                variant={'contained'}
                color="primary"
                size="large"
                type="submit"
              >
                {t('login.button')}
              </Button>
            </div>
          </div>
        </Box>
        <div className={classes.Forgot}>
          <Link href={'/forgot-password'}>{t('login.password-forgot-link')}</Link>
        </div>

        {lendisOsSso && <SocialSignOn t={t} />}

        <EnterpriseSignOn t={t} />

        <FooterContent
          headerText={t('login.helper-test-heading')}
          helperText={t('login.helper-text')}
        />
      </div>
    </section>
  );
};
