import React from 'react';
import { Tooltip as MaterialTooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

export const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.primary.light,
  },
  [`& .${tooltipClasses.tooltipPlacementTop}`]: {
    marginBottom: '8px !important',
  },
}));
