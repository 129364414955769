import { makeStyles } from 'tss-react/mui';

const contentMaxWidth = '358px';
export const UserManagementScreensStyles = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '100vh',
    width: '100vw',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.default,
  },
  wrap: {
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  ButtonContainer: {
    width: '100%',
    textAlign: 'center',
    marginTop: '15px',
  },
  InputsContainer: {
    width: contentMaxWidth,
    display: 'grid',
    gridTemplateColumns: 'repeat(1, 100%)',
    [theme.breakpoints.down('sm')]: {
      width: '95vw',
    },
  },
  Welcome: {
    maxWidth: contentMaxWidth,
    margin: '0 auto',
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '22px',
  },
  Forgot: {
    paddingTop: '20px',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  Error: {
    textAlign: 'center',
    color: theme.palette.error.main,
    paddingBottom: '20px',
  },
  cover: {
    maxWidth: contentMaxWidth,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  span: {
    color: theme.palette.primary.main,
  },
  agreementMessage: {
    width: '70%',
    paddingTop: '5px',
    height: '70px',
    lineHeight: '143%',
    textAlign: 'center',
    letterSpacing: '0.17px',
    color: theme.palette.primary.main,
  },
  link: {
    width: '100%',
    paddingTop: '20px',
    height: '70px',
    lineHeight: '143%',
    textAlign: 'center',
    letterSpacing: '0.17px',
    color: theme.palette.primary.main,
  },
  Img: {
    maxWidth: contentMaxWidth,
  },
}));
