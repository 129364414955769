import { logException } from '@platform/hooks';

type EnvName =
  | 'stage'
  | 'amplifyUserPoolId'
  | 'amplifyUserPoolWebClientId'
  | 'reactAppBaseURL'
  | 'reactAppMicroServiceBaseUrl'
  | 'algoliaAppId'
  | 'algoliaProfileIndex'
  | 'algoliaTaskIndex'
  | 'algoliaProductIndex'
  | 'algoliaProductApiKey'
  | 'sentryDsn'
  | 'sentryEnvironment'
  | 'reactAppOrderServiceBaseUrl'
  | 'cloudinaryCloudName'
  | 'launchDarklyClientId'
  | 'ssoDomain'
  | 'adminAppBaseURL';

type Options = {
  noThrow?: boolean;
};

export const getEnv = (name: EnvName, options: Options = {}): string => {
  const value = {
    stage: process.env.REACT_APP_STAGE,
    cloudinaryCloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
    amplifyUserPoolId: process.env.REACT_APP_USER_POOL_ID,
    amplifyUserPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    reactAppBaseURL: process.env.REACT_APP_USER_BASE_URL,
    reactAppMicroServiceBaseUrl: process.env.REACT_APP_MICRO_SERVICE_BASE_URL,
    reactAppOrderServiceBaseUrl: process.env.REACT_APP_ORDER_SERVICE_BASE_URL,
    algoliaAppId: process.env.REACT_APP_ALGOLIA_APP_ID,
    algoliaProfileIndex: process.env.REACT_APP_ALGOLIA_API_PROFILE_INDEX,
    algoliaTaskIndex: process.env.REACT_APP_ALGOLIA_API_TASKS_INDEX,
    algoliaProductApiKey: process.env.REACT_APP_ALGOLIA_API_PRODUCT_KEY,
    algoliaProductIndex: process.env.REACT_APP_ALGOLIA_API_PRODUCT_INDEX,
    sentryDsn: process.env.REACT_APP_SENTRY_DSN,
    sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    launchDarklyClientId: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
    ssoDomain: process.env.REACT_APP_SSO_DOMAIN,
    adminAppBaseURL: process.env.REACT_APP_ADMIN_APP_BASE_URL,
  }[name];

  if (value || options.noThrow) {
    return value || '';
  }

  const error = new Error(`ENV var missing: ${name}`);
  error.name = 'ENV_MISSING_ERROR';
  logException(error);
  throw error;
};
export const minuteInMs = 1000 * 60;
export const dayInMs = minuteInMs * 60 * 24;
