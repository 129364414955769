import { Box, Button, Menu, MenuItem, Theme } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import React, { useState } from 'react';

declare global {
  interface Window {
    JIRA_COLLECTORS: Record<string, () => null>;
  }
}

type FeedbackProps = {
  theme: Theme;
  buttonText: string;
  raiseBugText: string;
  submitIdeaText: string;
};

export const Feedback: React.FC<FeedbackProps> = ({
  theme,
  buttonText,
  raiseBugText,
  submitIdeaText,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const openJIRACollector = (collectorName: string) => {
    window.JIRA_COLLECTORS[collectorName]();
    handleClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="fixed left-full top-[40%] z-10">
        <Box sx={{ transform: 'rotate(90deg)', transformOrigin: 'top left' }}>
          <Button
            color="secondary"
            className="rounded-b-lg rounded-t-none"
            variant="contained"
            onClick={handleClick}
            id="feedback"
          >
            {buttonText}
          </Button>
        </Box>
        <div>
          <Menu
            open={isOpen}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
          >
            <MenuItem onClick={() => openJIRACollector('idea')}>{submitIdeaText}</MenuItem>
            <MenuItem onClick={() => openJIRACollector('bug')}>{raiseBugText}</MenuItem>
          </Menu>
        </div>
      </Box>
    </ThemeProvider>
  );
};
