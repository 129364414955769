import { makeStyles } from 'tss-react/mui';

export const FooterContentStyles = makeStyles()((theme) => ({
  FooterContent: {
    margin: '20px',
    minHeight: '92px',
    width: '358px',
    '@media (max-height: 800px)': {
      width: '320px',
      marginTop: '40px',
    },
  },
  Content: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    '@media (max-height: 800px)': {
      position: 'relative',
    },
  },
}));
