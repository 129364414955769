import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardProps,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import React, { ReactElement } from 'react';
import { useTranslation } from '@platform/hooks';
import { useCardStyles } from './ProductCard.style';

export type ProductCardProps = {
  title: string;
  titleExtra?: string;
  description?: string;
  selected?: boolean;
  promoted?: boolean;
  sx?: CardProps['sx'];
  onDetailsClick?: () => void;
  onSelect?: () => void;
  PriceUnderline?: React.FC<any>;
  buttonSize?: ButtonProps['size'];
  buttonText: string;
  detailsButtonText?: string;
  showDetailsButton?: boolean;
  buttonSelectedText: string;
  priceMonthly?: string;
  CardImage: ReactElement;
};

export const ProductCard: React.FC<ProductCardProps> = ({
  title,
  titleExtra,
  description,
  selected = false,
  promoted = false,
  sx,
  onDetailsClick,
  onSelect,
  PriceUnderline,
  buttonSize = 'small',
  buttonText,
  buttonSelectedText,
  showDetailsButton = true,
  priceMonthly,
  detailsButtonText,
  CardImage,
}) => {
  const { t } = useTranslation();
  const { classes, cx } = useCardStyles();

  return (
    <Card
      variant="outlined"
      className={cx(classes.cardWrapper, { [classes.selectedCardWrapper]: selected })}
      sx={sx}
    >
      {promoted && (
        <Typography
          data-testid="product-card-promoted-badge"
          variant="caption"
          className="absolute bg-primary-main text-primary-contrastText rounded-br-xl px-3 py-0.5"
        >
          {t('product-card.top-seller')}
        </Typography>
      )}
      <Box className="bg-white">{CardImage}</Box>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6">
          {title}
          {titleExtra && (
            <>
              {' '}
              <span className="font-normal whitespace-nowrap">{titleExtra}</span>
            </>
          )}
        </Typography>
        <Typography variant="body2" className="grow">
          {description}
        </Typography>
        {PriceUnderline && (
          <Box>
            <Typography variant="body1" className={classes.price}>
              {priceMonthly}
            </Typography>
            <PriceUnderline className="w-14 block fill-secondary-main" />
          </Box>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        {showDetailsButton && (
          <Button
            size={buttonSize}
            color="primary"
            data-testid="product-card-details-btn"
            variant="outlined"
            onClick={onDetailsClick}
          >
            {detailsButtonText}
          </Button>
        )}
        <Button
          size={buttonSize}
          color={selected ? 'secondary' : 'primary'}
          variant={selected ? 'outlined' : 'contained'}
          className={selected ? 'bg-white text-text-primary' : ''}
          data-testid="product-card-btn"
          onClick={onSelect}
          endIcon={selected ? <CheckIcon /> : undefined}
        >
          {selected ? buttonSelectedText : buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};
