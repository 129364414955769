import { Palette, Theme } from '@mui/material';
import { RemoveProps } from './types';

type GenericObject = Record<string, any>;
type PaletteObjProps = RemoveProps<
  Palette,
  Palette['contrastThreshold'] | Palette['augmentColor'] | Palette['getContrastText']
>;

const PREFIX = 'dynamic';
const OPACITY = 'opacity';

export const createCSSVars = (object: GenericObject): GenericObject => {
  const prefix = `--${PREFIX}-`;

  return generateCSSVarNames(prefix, object);
};

const generateCSSVarNames = (prefix: string, object: GenericObject): GenericObject => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => {
      let varName = prefix + key;

      if (typeof value === 'object') {
        varName += '-';
        return [key, generateCSSVarNames(varName, value)];
      }

      if (isColor(value)) {
        return [key, withOpacity(varName)];
      }

      return [key, `var(${varName})`];
    }),
  );
};

const withOpacity = (varName: string) => {
  return ({ opacityValue }: { opacityValue: string }) => {
    if (opacityValue !== undefined && !isNaN(Number(opacityValue))) {
      return `rgb(var(${varName}) / ${opacityValue})`;
    }
    // Try to use the default color opacity
    return `rgb(var(${varName}) / var(${varName}-${OPACITY}, 1))`;
  };
};

export const setTailwindColors = (theme: Theme) => {
  if (!isBrowser) {
    throw new Error('Trying to set a CSS variable outside of a browser context');
  }

  setCSSVarColors(getPaletteColorProps(theme.palette), PREFIX);
};

export const getPaletteColorProps = (palette: Palette): PaletteObjProps => {
  return Object.fromEntries(
    Object.entries(palette).filter(([_, value]) => typeof value === 'object'),
  ) as PaletteObjProps;
};

const setCSSVarColors = (object: GenericObject, prefix: string) => {
  const entries = Object.entries(object);
  for (const [key, value] of entries) {
    const newPrefix = `${prefix}-${key}`;

    if (typeof value === 'object') {
      setCSSVarColors(value, newPrefix);
    } else {
      if (isColor(value)) {
        const { color, alpha } = getRGBColor(value);

        document.documentElement.style.setProperty(`--${newPrefix}`, color);
        if (alpha) {
          document.documentElement.style.setProperty(`--${newPrefix}-${OPACITY}`, alpha);
        }
      } else {
        document.documentElement.style.setProperty(`--${newPrefix}`, value);
      }
    }
  }
};

export const isBrowser = typeof window !== 'undefined';

const getRGBColor = (colorVal: string): { color: string; alpha: string | undefined } => {
  if (isHex(colorVal)) {
    return parseHex(colorVal);
  }

  return parseRGB(colorVal);
};

const isColor = (value: unknown) => {
  if (typeof value !== 'string') {
    return false;
  }

  return isHex(value) || isRGB(value);
};

// Check if the value starts with '#' and [4 -> #f00], [5 -> #f009] (alpha), [7 -> #ff0000], [9 -> #ff0000a8] (alpha)
const isHex = (value: string) => value.startsWith('#') && [4, 5, 7, 9].includes(value.length);
// Check if the value starts with 'rbg', making 'rbg' and 'rgba' valid
const isRGB = (value: string) => value.startsWith('rgb');

const parseHex = (colorVal: string) => {
  //Remove '#' char
  const hexColor = colorVal.slice(1);

  let size: number;
  let hexVal: (val: string) => string;

  if ([3, 4].includes(hexColor.length)) {
    // Set configs for short hex (with/without alpha)
    size = 1;
    hexVal = (hex: string) => hex + hex;
  } else if ([6, 8].includes(hexColor.length)) {
    // Set configs for long hex (with/without alpha)
    size = 2;
    hexVal = (hex: string) => hex;
  } else {
    throw new Error(`Invalid hex color: ${colorVal}`);
  }

  // Split hex into chunks of 1 or 2 chars depending on the hex size
  const expression = new RegExp(`.{${size}}`, 'g');
  // Parse the chunks into decimal value (0, 255) range
  const colorNumbers = hexColor.match(expression)!.map((hex) => parseInt(hexVal(hex), 16));
  // Transform the alpha value into (0, 1) range
  const alpha = colorNumbers[3]
    ? parseFloat((colorNumbers[3] / 255).toFixed(2)).toString()
    : undefined;
  return { color: colorNumbers.slice(0, 3).join(' '), alpha };
};

const parseRGB = (colorVal: string) => {
  let size: number;

  // Get the size of the number of characters to remove
  if (colorVal.startsWith('rgb(')) {
    size = 4;
  } else if (colorVal.startsWith('rgba(')) {
    size = 5;
  } else {
    throw new Error(`Not a rgb color: ${colorVal}`);
  }

  // Remove 'rgb(' or 'rgba(' and last char ')' then split by ',' and remove spaces
  const colorNumbers = colorVal
    .substring(size, colorVal.length - 1)
    .split(',')
    .map((color) => color.trim());

  // If the resulting array is not size 3 (no alpha) or 4 (alpha) throw exception
  if (![3, 4].includes(colorNumbers.length)) {
    throw new Error(`Invalid rgb color: ${colorVal}`);
  }

  return { color: colorNumbers.slice(0, 3).join(' '), alpha: colorNumbers[3] };
};
