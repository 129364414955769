import React, { FC, FormEventHandler, SVGProps, useState } from 'react';
import { useAuth } from '@platform/auth';
import { Box, Button, Link, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import isEmail from 'validator/lib/isEmail';
import { UserManagementScreensStyles } from '../../UserManagementScreens.styles';
import ForgotPasswordSuccess from '../ForgotPasswordSuccess/ForgotPasswordSuccess';
import { TFunction } from 'react-i18next';
import FooterContent from '../AuthFooterContent/FooterContent';

export const ForgotPassword = ({
  t,
  passwordImage,
}: {
  t: TFunction;
  passwordImage: FC<SVGProps<SVGSVGElement>>;
}) => {
  const { classes } = UserManagementScreensStyles();
  const auth = useAuth();
  const [error, setError] = useState({
    message: '',
    error: false,
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    const email: string = formData.get('email') as string;

    if (!isEmail(email)) {
      setError({
        error: true,
        message: t('global.invalid-email-format'),
      });
      return;
    }

    try {
      setLoading(true);
      await auth.forgotPassword(email);
      setSuccess(true);
    } catch (err) {
      setError({
        error: true,
        message: t('global.error-occurred'),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {success ? (
        <ForgotPasswordSuccess ForgotPasswordImage={passwordImage} t={t} />
      ) : (
        <section className={classes.container}>
          <div className={classes.wrap}>
            <div className={classes.Welcome}>
              <Typography variant="h4">{t('forgot-password.heading')}</Typography>
            </div>
            <div className={classes.Error}>
              <Typography variant="subtitle2">{error.message}</Typography>
            </div>
            <Box
              onSubmit={onSubmit}
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1 },
              }}
            >
              <div className={classes.InputsContainer}>
                <TextField
                  label={<>{t('global.email')}</>}
                  type="text"
                  variant="filled"
                  error={error.error}
                  id="email-input"
                  name="email"
                  placeholder="Email"
                  disabled={loading}
                />
              </div>
              <div>
                <div className={classes.ButtonContainer}>
                  <Button
                    disabled={loading}
                    variant={'contained'}
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    {t('forgot-password.button')}
                  </Button>
                </div>
                <Typography variant="body2" className={classes.link}>
                  <Link href={'/login'}>{t('forgot-password.back-to-login')}</Link>
                </Typography>
              </div>
            </Box>
            <FooterContent
              headerText={t('forgot-password.helper-test-heading')}
              helperText={t('forgot-password.helper-text')}
            />
          </div>
        </section>
      )}
    </>
  );
};
