import React, { FormEventHandler, useState } from 'react';
import { useAuth } from '@platform/auth';
import { TFunction } from 'react-i18next';
import { Box, Button, TextField, Typography } from '@mui/material';
import { UserManagementScreensStyles } from '../../UserManagementScreens.styles';

export const EnterpriseSignOnModal = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const auth = useAuth();
  const { classes } = UserManagementScreensStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    error: false,
  });

  const onSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    const formData = new FormData(form);
    const orgName = formData.get('orgName') as string;

    if (!orgName) {
      setError({
        error: true,
        message: t('login.invalid-org-name'),
      });
      return;
    }

    setLoading(true);
    // Check if orgName exists
    const userPoolClientId = await auth.getEnterpriseIdpClient(orgName);

    if (!userPoolClientId) {
      setError({
        error: true,
        message: t('login.invalid-org-name'),
      });
      setLoading(false);
      return;
    }

    await auth.enterpriseSSO(userPoolClientId, orgName);
  };

  return (
    <div className="pb-5 center">
      <Typography variant="h5" sx={{ fontWeight: 500, padding: '30px' }}>
        {t('login.corporate-login-header')}
      </Typography>
      <div className={classes.Error}>
        <Typography variant="subtitle2">{error.message}</Typography>
      </div>

      <Box
        onSubmit={onSubmit}
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1 },
        }}
      >
        <div className="mx-5">
          <TextField
            fullWidth
            label={<>{t('login.organisation-name')}</>}
            type="text"
            variant="filled"
            error={error.error}
            name="orgName"
            placeholder="Organisation Name"
            disabled={loading}
          />
        </div>
        <div>
          <div className={classes.ButtonContainer}>
            <Button
              variant={'contained'}
              color="primary"
              size="large"
              type="submit"
              disabled={loading}
            >
              {t('login.button')}
            </Button>
          </div>
        </div>
      </Box>
    </div>
  );
};
