import { getEnv } from './helpers';
import { setupClient } from '@platform/auth';

export const authClient = setupClient(
  {
    userPoolId: getEnv('amplifyUserPoolId'),
    userPoolWebClientId: getEnv('amplifyUserPoolWebClientId'),
    oauth: {
      // Redirect hostname need to be configured on Cognito
      redirectSignIn: `${window.location.origin}/login`,
      redirectSignOut: `${window.location.origin}/login`,
      domain: getEnv('ssoDomain'),
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      responseType: 'token',
    },
  },
  getEnv('reactAppBaseURL')!,
);
