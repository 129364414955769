import { atom } from 'jotai';
import { CategorySlug, Product, ProfileEquipmentCategory } from '../../../types';

export const LOCALSTORAGE_KEY = 'lendis_workplace_configuration';
const DEFAULT_QUANTITY_TO_SELECT = 1;

export const configurationAtom = atom<Configuration>({});

const getStoredConfiguration = () => {
  try {
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY)!) ?? {};
  } catch (error) {
    return {};
  }
};

export type CategoryState = {
  status?: 'completed' | 'skipped';
  products: Product[];
};

export type Configuration = Partial<Record<CategorySlug, CategoryState>>;

type ConfigurationAction =
  | {
      type: 'TOGGLE_PRODUCT';
      payload: {
        product: Product;
        category: number;
      };
    }
  | {
      type: 'TOGGLE_SKIP_CATEGORY';
      payload: {
        category: number;
      };
    };

export const createReducer =
  (profileCategories: ProfileEquipmentCategory[]) =>
  (value: Configuration, action: ConfigurationAction) => {
    const currentRule = profileCategories[action.payload?.category];
    const currentConfiguration = value[currentRule?.category.slug] || { products: [] };

    let changedConfiguration = value;

    switch (action.type) {
      case 'TOGGLE_PRODUCT': {
        const product = action.payload.product;
        const productIndex = currentConfiguration.products.findIndex(
          (p) => p.lendis_id === product.lendis_id,
        );
        let products;
        if (productIndex === -1) {
          if (DEFAULT_QUANTITY_TO_SELECT === currentConfiguration.products.length) {
            products = [...currentConfiguration.products.slice(1), product];
          } else {
            products = [...currentConfiguration.products, product];
          }
        } else {
          products = [
            ...currentConfiguration.products.slice(0, productIndex),
            ...currentConfiguration.products.slice(productIndex + 1),
          ];
        }

        const status = products.length === DEFAULT_QUANTITY_TO_SELECT ? 'completed' : undefined;

        changedConfiguration = {
          ...value,
          [currentRule.category.slug]: {
            ...currentConfiguration,
            status,
            products,
          },
        };
        break;
      }
      case 'TOGGLE_SKIP_CATEGORY': {
        if (currentRule.rules.isMandatory) {
          return value;
        }
        changedConfiguration = {
          ...value,
          [currentRule.category.slug]: {
            products: [],
            status: currentConfiguration.status === 'skipped' ? undefined : 'skipped',
          },
        };
        break;
      }
    }

    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(changedConfiguration));
    return changedConfiguration;
  };

export const restoreConfigFromLocalStorage = () => {
  const storedConfiguration = getStoredConfiguration();
  return Object.keys(storedConfiguration).length > 0 ? storedConfiguration : {};
};
