import { AuthProvider } from '@platform/auth';
import { BrowserRouter as Router } from 'react-router-dom';
import setupI18n from './utils/setupI18n';
import { Provider as JotaiProvider } from 'jotai';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import { authClient } from './utils/authClient';
import theme from './theme';
import Routes from './Routes';
import { Layout } from './components/Layout';
import AlgoliaProvider from './components/AlgoliaProvider';
import CompanyCustomisationsProvider from './components/CompanyCustomisationsProvider';
import { Suspense } from 'react';
import { Loading } from '@platform/ui';
import { DomainPortal } from '@platform/hooks';

const i18n = setupI18n();

function App() {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } },
  });
  new DomainPortal({});
  return (
    <Suspense fallback={<Loading />}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <I18nextProvider i18n={i18n}>
            <AuthProvider client={authClient}>
              <AlgoliaProvider>
                <JotaiProvider>
                  <CompanyCustomisationsProvider theme={theme}>
                    <Layout>
                      <Routes />
                    </Layout>
                  </CompanyCustomisationsProvider>
                </JotaiProvider>
              </AlgoliaProvider>
            </AuthProvider>
          </I18nextProvider>
        </Router>
      </QueryClientProvider>
    </Suspense>
  );
}

export default App;
