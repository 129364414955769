import React, { Suspense, useCallback } from 'react';
import {
  Navigate,
  Route,
  Routes as RoutesWrapper,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { AutologinHandler, Loading } from '@platform/ui';
import RouteWrapper from './components/RouteWrapper';

const Landing = React.lazy(() => import('./pages/landing/Landing'));
const Login = React.lazy(() => import('./pages/login/Login'));
const SetupPassword = React.lazy(() => import('./pages/setup-password/SetupPassword'));
const ConfiguratorPage = React.lazy(() => import('./pages/configurator/ConfiguratorPage'));
const Address = React.lazy(() => import('./pages/address/Address'));
const Welcome = React.lazy(() => import('./pages/welcome/Welcome'));
const OrderSuccess = React.lazy(() => import('./pages/order-success/OrderSuccess'));
const Orders = React.lazy(() => import('./pages/orders/Orders'));
const LoginSuccess = React.lazy(() => import('./pages/login-success/LoginSuccess'));
const ForgotPassword = React.lazy(() => import('./pages/forgot-password/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/reset-password/ResetPassword'));
const NotAuthorized = React.lazy(() => import('./pages/not-authorized/NotAuthorized'));
const Expired = React.lazy(() => import('./pages/expired/Expired'));
const PageNotFound = React.lazy(() => import('./pages/page-not-found/PageNotFound'));

export const ROUTE_APP_BAR_VISIBILITY: { [key: string]: boolean } = {
  welcome: true,
  orders: true,
  'order-success': true,
};

const RouteConfig = [
  {
    id: 'los--employeeApp__landing',
    route: '/',
    isPrivateRoute: false,
    isAuthRoute: false,
    component: Landing,
  },
  {
    id: 'los--employeeApp__welcome',
    route: '/welcome',
    isPrivateRoute: true,
    isAuthRoute: false,
    component: Welcome,
  },
  {
    id: 'los--employeeApp__configurator',
    route: '/configurator',
    isPrivateRoute: true,
    isAuthRoute: false,
    component: ConfiguratorPage,
  },
  {
    id: 'los--employeeApp__address',
    route: '/address',
    isPrivateRoute: true,
    isAuthRoute: false,
    component: Address,
  },
  {
    id: 'los--employeeApp__orderSuccess',
    route: '/order-success/:order_id',
    isPrivateRoute: true,
    isAuthRoute: false,
    component: OrderSuccess,
  },
  {
    id: 'los--employeeApp__orders',
    route: '/orders',
    isPrivateRoute: true,
    isAuthRoute: false,
    component: Orders,
  },
  {
    id: 'los--employeeApp__setupPassword',
    route: '/setup-password',
    isPrivateRoute: false,
    isAuthRoute: false,
    component: SetupPassword,
  },
  {
    id: 'los--employeeApp__login',
    route: '/login',
    isPrivateRoute: false,
    isAuthRoute: true,
    component: Login,
  },
  {
    id: 'los--employeeApp__loginSuccess',
    route: '/login-successful',
    isPrivateRoute: false,
    isAuthRoute: true,
    component: LoginSuccess,
  },
  {
    id: 'los--employeeApp__forgotPassword',
    route: '/forgot-password',
    isPrivateRoute: false,
    isAuthRoute: true,
    component: ForgotPassword,
  },
  {
    id: 'los--employeeApp__resetPassword',
    route: '/reset-password/:token',
    isPrivateRoute: false,
    isAuthRoute: false,
    component: ResetPassword,
  },
  {
    id: 'los--employeeApp__notAuthorized',
    route: '/not-authorized',
    isPrivateRoute: false,
    isAuthRoute: false,
    component: NotAuthorized,
  },
  {
    id: 'los--employeeApp__expired',
    route: '/expired',
    isPrivateRoute: false,
    isAuthRoute: false,
    component: Expired,
  },
  {
    id: 'los--employeeApp__pageNotFound',
    route: '/page-not-found',
    isPrivateRoute: false,
    isAuthRoute: false,
    component: PageNotFound,
  },
];

const Routes = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const autologinToken = new URLSearchParams(searchParams).get('autologin');
  const handleAutologinError = useCallback(() => {
    navigate('/expired');
  }, [navigate]);
  return (
    <Suspense fallback={<Loading />}>
      {autologinToken && (
        <AutologinHandler
          token={autologinToken}
          onError={handleAutologinError}
          useErrorBoundary={false}
        >
          <Navigate to="/setup-password" />
        </AutologinHandler>
      )}
      <RoutesWrapper>
        {RouteConfig.map((route) => {
          const Component = route.component;
          return (
            <Route
              key={route.id}
              path={route.route}
              element={
                <RouteWrapper isPrivateRoute={route.isPrivateRoute} isAuthRoute={route.isAuthRoute}>
                  <Component />
                </RouteWrapper>
              }
            />
          );
        })}
        {!autologinToken && <Route path="*" element={<Navigate to="/page-not-found" replace />} />}
      </RoutesWrapper>
    </Suspense>
  );
};

export default Routes;
