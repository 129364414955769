import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-phone-input-2/lib/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getEnv } from './utils/helpers';
import { initLogging } from '@platform/hooks';
import { ErrorBoundary } from '@platform/ui';
// import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

const dsn = getEnv('sentryDsn', { noThrow: getEnv('stage') === 'local' });
const environment = getEnv('sentryEnvironment');

dsn && environment && initLogging({ dsn, environment });

(async () => {
  // const clientSideID = getEnv('launchDarklyClientId');
  // const LDProvider = await asyncWithLDProvider({ clientSideID });
  ReactDOM.render(
    <ErrorBoundary>
      <React.StrictMode>
        {/* <LDProvider> */}
        <App />
        {/* </LDProvider> */}
      </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
