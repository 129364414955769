import React, { cloneElement, useEffect, useRef } from 'react';
import { Palette, PaletteColor, useTheme } from '@mui/material';

type DynamicOutlineSVGProps = {
  outline: string;
  children: JSX.Element;
};

function isPaletteColor(value: any): value is PaletteColor {
  return typeof value === 'object' && !!(value as PaletteColor).main;
}

const doChildrenContain = (children: HTMLCollection, name: string, value: string): boolean => {
  for (const child of Array.from(children)) {
    if (child.children.length && doChildrenContain(child.children, name, value)) {
      return true;
    }

    if (
      Array.from(child.attributes).find((attr) => attr.name === name && attr.value.includes(value))
    ) {
      return true;
    }
  }
  return false;
};

export const DynamicOutlineSVG: React.FC<DynamicOutlineSVGProps> = ({ outline, children }) => {
  const childRef = useRef<HTMLHeadingElement>();
  const theme = useTheme();

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      if (!doChildrenContain(childRef.current!.children, 'fill', '--svg-outline-color')) {
        console.error(
          'The SVG passed does not contain a outline css variable defined.\n Please use "--svg-outline-color" to set that variable',
        );
      }
    }
  }, [children]);

  if (outline && outline in theme.palette) {
    const palette = theme.palette[outline as keyof Palette];

    if (isPaletteColor(palette)) {
      outline = palette.main;
    }
  }

  return cloneElement(children, { ref: childRef, style: { '--svg-outline-color': outline } });
};
