import { useAuth } from '@platform/auth';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { useAlgolia } from '@platform/hooks';
type TaskType = 'profile-invite';

type Task = {
  id: string;
  customer_id: string;
  profileId: string;
  orderId?: string;
  state: string;
  type: TaskType;
  email: string;
};

export const useTasks = () => {
  const { user, isAuthenticated } = useAuth()!;
  const email = user?.attributes?.email;
  const taskIndex = useAlgolia('tasks');
  const {
    data: tasks,
    isLoading,
    isError,
  } = useQuery(
    [`tasks`, email],
    async () => (await taskIndex.search<Task>('', { filters: `email: ${email}` })).hits,
    { enabled: isAuthenticated, cacheTime: 1, staleTime: 1 },
  );

  const getTaskByType = useCallback(
    (type: TaskType): Task | undefined => {
      if (!isLoading) {
        return tasks ? tasks.find((task) => task.type === type) : undefined;
      }
    },
    [isLoading, tasks],
  );

  return { tasks, isLoading, isError, getTaskByType };
};
