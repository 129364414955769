import React from 'react';
import PageNotFoundIMG from './PageNotFoundIMG';
import { Slide } from '@mui/material';
import FooterContent from '../AuthFooterContent/FooterContent';
import { Box } from '@mui/system';

type PageNotFoundProps = {
  title: string;
  message: React.ReactElement;
  buttonLabel: string;
  onButtonClick: () => void;
};

export const PageNotFound = ({ title, message, buttonLabel, onButtonClick }: PageNotFoundProps) => {
  return (
    <>
      <Slide
        style={{
          paddingTop: '10vh',
        }}
        direction="up"
        in
        mountOnEnter
        unmountOnExit
      >
        <Box className="flex justify-center">
          <PageNotFoundIMG className="w-64 fill-secondary-main" />
        </Box>
      </Slide>
      <Box>
        <FooterContent
          headerText={title}
          helperText={message}
          buttonText={buttonLabel}
          buttonClick={onButtonClick}
        />
      </Box>
    </>
  );
};
