import { Color, PaletteOptions, SimplePaletteColorOptions } from '@mui/material';

type CustomColors<T> = T & {
  tabIndicator: string;
  svgSecondary: string;
  footerBackground: string;
};

export interface DefaultPaletteOptions<T = {}> extends PaletteOptions {
  primary?: SimplePaletteColorOptions & Partial<Color>;
  secondary?: SimplePaletteColorOptions & Partial<Color>;
  custom: CustomColors<T>;
}

export const colorPalette: DefaultPaletteOptions = {
  background: {
    default: '#DFDADB',
    paper: '#F2F2F2',
  },
  primary: {
    main: '#301E38',
    A100: '#493950',
    light: '#5A4662',
  },
  secondary: {
    main: '#90FF99',
  },
  action: {
    disabled: '#493950',
    disabledBackground: '#7A6E7C',
    disabledOpacity: 0.5,
    hover: 'rgba(255, 255, 255, 0.1)',
  },
  success: {
    main: '#2E7D32',
    light: '#90FF99',
  },
  text: {
    primary: '#301E38',
    secondary: '#7A6E7C',
    disabled: '#9B909D',
  },
  custom: {
    tabIndicator: '#90FF99',
    svgSecondary: '#301E38',
    footerBackground: '#301E38',
  },
  error: {
    main: '#F32F2F',
  },
};
