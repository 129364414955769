import React from 'react';
import { Button, Drawer, Typography } from '@mui/material';
import { useBottomDrawer, useTranslation } from '@platform/hooks';
import { Box } from '@mui/system';

export const BottomDrawer = () => {
  const { state, closeDrawer } = useBottomDrawer();
  const { t } = useTranslation();

  return (
    <Drawer
      classes={{
        paper: `bg-primary-main text-primary-contrastText rounded-t-2xl p-4 flex flex-col gap-2 ${
          state.fullHeight ? 'h-[calc(100%-60px)]' : 'min-h-[30vh]'
        }`,
      }}
      anchor="bottom"
      hideBackdrop={state.hideBackdrop}
      open={state.visible}
    >
      <Box className="flex justify-between">
        <Box>
          <Typography variant="h5">{state.title}</Typography>
          <Typography variant="subtitle2" className="text-dark-text-secondary">
            {state.subtitle}
          </Typography>
        </Box>
        {state.showCloseButton && (
          <Button
            className="p-0 h-fit"
            onClick={closeDrawer}
            size="large"
            color="inherit"
            data-testid="bottomdrawer-close"
          >
            {t('global.close')}
          </Button>
        )}
      </Box>
      <Box className="overflow-y-auto h-full">{state.content}</Box>
    </Drawer>
  );
};
