import React, { ChangeEvent, FormEventHandler } from 'react';
import { Box, Button, Link, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { PasswordValidationResponse } from '@platform/hooks';
import { UserManagementScreensStyles } from '../../UserManagementScreens.styles';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { TFunction } from 'react-i18next';
import { SocialSignOn } from '../SocialSignOn/SocialSignOn';
import FooterContent from '../AuthFooterContent/FooterContent';

type LabelError = {
  password: boolean;
  repeatPassword: boolean;
  firstname?: boolean;
  lastname?: boolean;
};

export const PasswordSetupBody = ({
  validationMessage,
  labelError,
  password,
  loading,
  handleChangePassword,
  repeatPassword,
  handleChangeRepeatPassword,
  helperText,
  handleCheckValidationAndSubmit,
  showAgreement,
  t,
  includeName,
  enableSSO,
  firstname,
  lastname,
  heading,
  handleChangeFirstName,
  handleChangeLastName,
}: {
  validationMessage: PasswordValidationResponse;
  labelError: LabelError;
  password: string;
  loading: boolean;
  handleChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
  repeatPassword: string;
  handleChangeRepeatPassword: (event: ChangeEvent<HTMLInputElement>) => void;
  helperText: EmotionJSX.Element | boolean;
  handleCheckValidationAndSubmit: () => void;
  showAgreement: boolean;
  t: TFunction;
  includeName?: boolean;
  enableSSO?: boolean;
  firstname?: string;
  lastname?: string;
  heading: string;
  handleChangeFirstName?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeLastName?: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const { classes } = UserManagementScreensStyles();

  const onFormSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    handleCheckValidationAndSubmit();
  };

  return (
    <section className={classes.container}>
      <div className={classes.wrap}>
        <div className={classes.Welcome}>
          <Typography variant="h4">{t(heading)}</Typography>
        </div>
        <div className={classes.Error}>
          <Typography variant="subtitle2">{validationMessage.message}</Typography>
        </div>

        <Box
          component="form"
          onSubmit={onFormSubmit}
          sx={{
            '& .MuiTextField-root': { m: 1 },
          }}
        >
          <div className={classes.InputsContainer}>
            {includeName && (
              <>
                <TextField
                  error={labelError.firstname}
                  label={<>{t('global.firstname')}</>}
                  type="text"
                  data-testid="firstname"
                  disabled={loading}
                  variant="filled"
                  value={firstname}
                  onChange={handleChangeFirstName}
                />{' '}
                <TextField
                  error={labelError.lastname}
                  label={<>{t('global.lastname')}</>}
                  type="text"
                  data-testid="lastname"
                  disabled={loading}
                  variant="filled"
                  value={lastname}
                  onChange={handleChangeLastName}
                />
              </>
            )}
            <TextField
              error={labelError.password}
              id="filled-password-input"
              label={<>{t('setup-password.password')}</>}
              type="password"
              data-testid="password"
              disabled={loading}
              variant="filled"
              value={password}
              onChange={handleChangePassword}
            />
            <TextField
              error={labelError.repeatPassword}
              value={repeatPassword}
              id="filled-confirm-password-input"
              label={<>{t('setup-password.repeat-password')}</>}
              data-testid="repeat-password"
              type="password"
              disabled={loading}
              variant="filled"
              onChange={handleChangeRepeatPassword}
              helperText={labelError.repeatPassword ? helperText : ''}
            />
          </div>
          {showAgreement && (
            <div style={{ color: 'red' }} className={classes.cover}>
              <Typography variant="body2" className={classes.agreementMessage}>
                <span className={classes.span}>{t('setup-password.i-have-read')}</span>
                <Link
                  href={'https://www.lendis.io/en/terms-conditions/'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>{t('setup-password.terms-conditions')} </b>
                </Link>
                <span className={classes.span}>&</span>
                <Link
                  href={'https://www.lendis.io/en/data-privacy/'}
                  target="_blank"
                  rel="noreferrer"
                >
                  <b>{t('setup-password.privacy')}</b>
                </Link>
              </Typography>
            </div>
          )}
          <div>
            <div className={classes.ButtonContainer}>
              <Button
                variant="contained"
                color="primary"
                data-testid="save-password-btn"
                size="large"
                disabled={loading}
                type="submit"
              >
                {t('forgot-password.save-password')}
              </Button>
            </div>
          </div>
        </Box>

        {enableSSO && <SocialSignOn isSignup t={t} />}

        {(password || repeatPassword) && (
          <FooterContent
            headerText={t('setup-password.helpTextTitle')}
            helperText={t('setup-password.info-text')}
            optionalhelperTextList={t('setup-password.rules-list', { returnObjects: true })}
          />
        )}
      </div>
    </section>
  );
};
