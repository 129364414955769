import React from 'react';
import { Chip } from '@mui/material';
import { Tooltip } from '../Tooltip/Tooltip';

export const ChipWithTooltip = ({
  label,
  title,
  color,
}: {
  label: string | number;
  title: string;
  color?: string;
}) => {
  return (
    <Tooltip title={title} arrow placement="top">
      <Chip
        label={label}
        sx={{
          '&.MuiChip-root': {
            marginRight: '14px',
            backgroundColor: color,
          },
        }}
      />
    </Tooltip>
  );
};
