import React from 'react';
import { ModalSize, useModal } from '@platform/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from '@mui/material';

export const Modal = () => {
  const { state, hideModal } = useModal();

  if (!state?.visible) {
    return null;
  }

  const getModalWidthSize = (size: ModalSize) => {
    switch (size) {
      case 'sm':
        return 'w-11/12 sm:w-3/4 md:w-3/5 lg:w-1/2 xl:w-2/5 2xl:w-1/3';
      case 'md':
        return 'w-7/12';
      case 'lg':
        return 'w-8/12';
    }
  };

  const getModalHeightSize = (size?: ModalSize) => {
    switch (size) {
      case 'sm':
        return 'h-4/12';
      case 'md':
        return 'h-7/12';
      case 'lg':
        return 'h-8/12';
      default:
        return '';
    }
  };

  const renderHeader = () => {
    if (!state.showCloseModalButton && !state.title) {
      return null;
    }
    return (
      <header className="flex justify-between">
        <Box className="pt-8 pl-8">
          {typeof state.title === 'string' ? (
            <Typography variant="h5" className="font-medium">
              {state.title}
            </Typography>
          ) : (
            state.title
          )}
        </Box>
        {state.showCloseModalButton && (
          <IconButton
            size="small"
            className="m-3 self-start"
            onClick={hideModal}
            data-testid="modal-close"
          >
            <CloseIcon />
          </IconButton>
        )}
      </header>
    );
  };

  return (
    <div
      className="inset-0 fixed z-20 h-full flex flex-col items-center justify-center"
      data-testid="modal"
    >
      <div
        onClick={() => state?.canBeClosed && hideModal()}
        className="absolute w-full h-full bg-gray-900 opacity-50"
      />
      <div
        className={`shadow rounded-lg relative bg-white overflow-auto max-w-4xl ${getModalWidthSize(
          state.width,
        )} ${getModalHeightSize(state.height)}`}
        style={{ maxHeight: '90vh' }}
      >
        {renderHeader()}
        {state?.content}
      </div>
    </div>
  );
};
