import React, { useEffect, useState } from 'react';
import { CssBaseline, Theme, ThemeProvider, createTheme } from '@mui/material';
import { CompanyCustomisationsContext, CompanyCustomisationsProviderProps } from '@platform/hooks';
import { useCompanyDetails } from '../hooks/useCompanyDetails';
import { setTailwindColors } from '@platform/ui';
import { darkTheme, palette, themeConfig } from '../theme';

// Export dynamic dark theme based on the orignal dynamic theme
export let dynamicDarkTheme = darkTheme;

const CompanyCustomisationsProvider: React.FC<CompanyCustomisationsProviderProps> = ({
  children,
  theme,
}) => {
  const { companyDetails } = useCompanyDetails();

  const [currentTheme, setCurrentTheme] = useState<Theme>(theme);

  const getCompanySetting = (settingKey: string) => {
    const companySettingValue = companyDetails?.settings?.find(
      (settingsItem) => settingsItem.type === settingKey,
    );
    return companySettingValue?.value?.toString() ?? null;
  };

  useEffect(() => {
    const primaryColour = getCompanySetting('primaryColor');

    if (!primaryColour) {
      return;
    }

    palette.primary!.main = '#000';
    palette.secondary!.main = primaryColour;
    palette.background!.default = '#FFF';
    palette.custom.footerBackground = '#000';
    palette.custom.svgSecondary = '#000';
    palette.custom.svgPrimary = primaryColour;
    palette.custom.tabIndicator = primaryColour;
    palette.custom.appBar = primaryColour;
    delete palette.primary!.light;
    delete palette.action;
    delete palette.text;

    const dynamicThemeConfig = {
      ...themeConfig,
      palette: {
        ...themeConfig.palette,
        ...palette,
      },
    };

    const dynamicTheme = createTheme(dynamicThemeConfig);

    // Create dynamic dark mode from the original dynamic theme
    dynamicThemeConfig.palette.mode = 'dark';
    dynamicDarkTheme = createTheme(dynamicThemeConfig);

    // Set custom theme values based on the created theme values
    dynamicTheme.palette.custom.appBarText = dynamicTheme.palette.secondary.contrastText;

    // Set MUI and Tailwind custom themes
    setCurrentTheme(dynamicTheme);
    setTailwindColors(dynamicTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDetails, theme]);

  return (
    <CompanyCustomisationsContext.Provider
      value={{
        companyDetails,
        getCompanySetting,
      }}
    >
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CompanyCustomisationsContext.Provider>
  );
};

export default CompanyCustomisationsProvider;
