import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enUS from '../locales/en-US.json';

const setupI18n = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: 'en-US',
    resources: {
      'en-US': { translation: enUS },
    },
    interpolation: {
      escapeValue: false,
    },
  });

  return i18n;
};
export default setupI18n;
