import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@platform/auth';
import { ErrorBoundary, Loading } from '@platform/ui';
import { useTasks } from '../hooks/useTasks';
import ErrorMessage from './ErrorMessage';

type RouteWrapperProps = {
  isPrivateRoute: boolean;
  isAuthRoute: boolean;
};

const RouteWrapper: React.FC<RouteWrapperProps> = ({ children, isPrivateRoute, isAuthRoute }) => {
  const { isAuthenticated, user, currentUserState } = useAuth();
  const { isLoading, getTaskByType } = useTasks();
  const task = getTaskByType('profile-invite');
  const navigate = useNavigate();
  useEffect(() => {
    if (currentUserState !== 'Loading') {
      if (isPrivateRoute && !isAuthenticated) {
        navigate('/login');
      } else if (isAuthRoute && isAuthenticated) {
        navigate('/');
      }
    }
    if (currentUserState === 'Authenticated' && !user.hasEmployeeAccess) {
      navigate('/not-authorized');
    }
  }, [
    user,
    isLoading,
    isAuthenticated,
    currentUserState,
    task,
    navigate,
    isPrivateRoute,
    isAuthRoute,
  ]);
  return currentUserState === 'Loading' ? (
    <Loading />
  ) : (
    <ErrorBoundary fallbackComponent={ErrorMessage}>{children}</ErrorBoundary>
  );
};

export default RouteWrapper;
