import React from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';

type CloudinaryImageProps = {
  url: string;
  className?: string;
  cloudName?: string;
  height?: number;
};

export const CloudinaryImage: React.FC<CloudinaryImageProps> = React.memo(
  ({ url, className, cloudName, height }) => {
    if (!url) {
      return null; // we should show a categorized placeholder image instead
    }

    if (!className) {
      className = 'w-full h-full';
    }

    const cloudinary = new Cloudinary({ cloud: { cloudName } });
    const image = cloudinary.image(decodeURIComponent(url));

    if (height) {
      image.resize(scale().height(height));
    }

    return (
      <AdvancedImage
        style={{ maxHeight: height }}
        className={`block ${className}`}
        cldImg={image}
        plugins={[lazyload(), responsive(), placeholder()]}
      />
    );
  },
);
