import { makeStyles } from 'tss-react/mui';

export const useLoadingStyles = makeStyles()(() => ({
  loadingImageAnimation: {
    margin: '0 auto',
    animation: 'loadingAnimation 2s linear infinite',
    '@keyframes loadingAnimation': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0 },
      '100%': { opacity: 1 },
    },
  },
}));
