import React, { ChangeEvent, useState } from 'react';
import { useQuery } from 'react-query';
import { useAuth } from '@platform/auth';
import { NavigateFunction } from 'react-router-dom';
import { encrypt } from '@lendis-tech/sdk';
import { PasswordMatch, PasswordValidationResponse, PasswordValidator } from '@platform/hooks';
import { PasswordSetupBody } from '../PasswordSetup/PasswordSetupBody';
import { TFunction } from 'react-i18next';
import { Expired } from '../ExpiredURL/Expired';

type ValidationsType = {
  passwordValidation: PasswordValidationResponse;
  repeatPasswordValidation: PasswordValidationResponse;
};

export const ResetPassword = ({
  t,
  navigate,
  token,
}: {
  t: TFunction;
  navigate: NavigateFunction;
  token: string;
}) => {
  const { confirmForgotPassword, getForgotPassword } = useAuth();
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [labelError, setLabelError] = useState({
    password: false,
    repeatPassword: false,
  });
  const [validationMessage, setValidationMessage] = useState<PasswordValidationResponse>({
    message: '',
    valid: false,
  });
  const [loading, setLoading] = useState(false);
  const helperText = repeatPassword.trim().length < 1 && <>{t('setup-password.helper-text')}</>;
  const tokenValidationCacheKey = `token-validation-${token}`;
  const { data, isLoading } = useQuery(tokenValidationCacheKey, () => getForgotPassword(token), {
    retry: false,
  });
  const onFormSubmit = async () => {
    try {
      setLoading(true);
      await confirmForgotPassword(encrypt(password), token);
      navigate('/login');
    } catch (e) {
      setValidationMessage({
        message: t('setup-password.validation.did-not-work'),
        valid: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeRepeatPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(event.target.value);
  };
  const handleCheckValidationAndSubmit = async () => {
    if (!Validations.passwordValidation.valid) {
      setLabelError({
        password: true,
        repeatPassword: false,
      });
      setValidationMessage({
        message: t(Validations.passwordValidation.message),
        valid: Validations.passwordValidation.valid,
      });
    } else if (!Validations.repeatPasswordValidation.valid) {
      setLabelError({
        password: false,
        repeatPassword: true,
      });
      setValidationMessage({
        message: t(Validations.repeatPasswordValidation.message),
        valid: Validations.repeatPasswordValidation.valid,
      });
    } else {
      setLabelError({
        password: false,
        repeatPassword: false,
      });
      onFormSubmit();
    }
  };
  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const Validations: ValidationsType = {
    passwordValidation: PasswordValidator(password),
    repeatPasswordValidation: PasswordMatch(password, repeatPassword),
  };
  if (!isLoading && data?.status !== 200) {
    return <Expired t={t} navigate={navigate} text={'expired-url.message-from-setup'} />;
  }
  return (
    <PasswordSetupBody
      validationMessage={validationMessage}
      labelError={labelError}
      password={password}
      loading={loading}
      handleChangePassword={handleChangePassword}
      repeatPassword={repeatPassword}
      handleChangeRepeatPassword={handleChangeRepeatPassword}
      helperText={helperText}
      handleCheckValidationAndSubmit={handleCheckValidationAndSubmit}
      showAgreement={false}
      t={t}
      heading={'setup-password.reset-password'}
    />
  );
};
