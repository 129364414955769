import React from 'react';
import { Slide } from '@mui/material';
import { useAuth } from '@platform/auth';
import { TFunction } from 'react-i18next';
import FooterContent from '../AuthFooterContent/FooterContent';
import { Box } from '@mui/system';
import ExpiredIMG from './ExpiredIMG';
import { NavigateFunction } from 'react-router-dom';

export const Expired = ({
  t,
  navigate,
  text,
}: {
  t: TFunction;
  navigate: NavigateFunction;
  text: string;
}) => {
  const auth = useAuth();

  React.useEffect(() => {
    auth.logout();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Slide
        style={{
          paddingTop: '10vh',
        }}
        direction="up"
        in
        mountOnEnter
        unmountOnExit
      >
        <Box className="flex justify-center">
          <ExpiredIMG className="fill-secondary-main w-80" />
        </Box>
      </Slide>
      <Box>
        <FooterContent
          headerText={t('expired-url.title')}
          helperText={t(text)}
          buttonText={t('global.back-to-login')}
          buttonClick={() => navigate('/')}
        />
      </Box>
    </>
  );
};
