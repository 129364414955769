import React from 'react';
import { useModal } from '@platform/hooks';
import { TFunction } from 'react-i18next';
import { Button, Typography } from '@mui/material';
import { UserManagementScreensStyles } from '../../UserManagementScreens.styles';
import { EnterpriseSignOnModal } from './EnterpriseSignOnModal';

export const EnterpriseSignOn = ({ t }: { t: TFunction<'translation', undefined> }) => {
  const { classes } = UserManagementScreensStyles();

  const { showModal } = useModal();

  const showEnterpriseLoginModal = () => {
    showModal({
      content: <EnterpriseSignOnModal t={t} />,
      width: 'lg',
      height: 'md',
      showCloseModalButton: false,
    });
  };

  return (
    <div className="mt-4 mx-2">
      <div className="relative flex py-3 items-center">
        <hr className="flex-grow bg-gray-300" />
        <span className="flex-shrink px-2 text-gray-500 text-sm">OR</span>
        <hr className="flex-grow bg-gray-300" />
      </div>

      <div className="pt-5 text-center">
        <Typography variant="subtitle1">{t('login.enterprise-check')}</Typography>
      </div>
      <div className={classes.ButtonContainer}>
        <Button
          onClick={showEnterpriseLoginModal}
          variant={'outlined'}
          color="primary"
          size="medium"
          type="submit"
        >
          {t('login.corporate-login-button')}
        </Button>
      </div>
    </div>
  );
};
