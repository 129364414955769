import { makeStyles } from 'tss-react/mui';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  drawerwidth: number;
}
export const LayoutStyles = makeStyles()((theme) => ({
  container: {
    minHeight: '100vh',
    width: '100vw',
  },
  avatar: {
    paddingLeft: '10px',
    paddingRight: '30px',
  },
  appBar: {
    [theme.breakpoints.down('sm')]: {
      border: `none`,
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
  },
  listFlexEnd: {
    justifyContent: 'flex-end',
  },
  icon: {
    color: theme.palette.action.active,
  },
  hamburger: {
    color: theme.palette.primary.main,
  },
  toolBar: {
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.background.default,
    },
  },
  chevron: {
    color: theme.palette.primary.contrastText,
    fontSize: '60px',
    height: '70px',
  },
  headertext: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.main,
    },
  },
  content: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
}));

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
  drawerwidth: number;
}>(({ theme, open, drawerwidth }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerwidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerwidth}px)`,
    marginLeft: `${drawerwidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
