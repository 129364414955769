import React from 'react';
import * as Sentry from '@sentry/react';

type ErrorBoundaryProps = {
  fallbackComponent?: React.FC<{
    errorId?: string;
  }>;
};

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children,
  fallbackComponent: Fallback,
}) => {
  const errorId = Math.random().toString(36).slice(2); // Random unique string
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => scope.setTag('traceId', errorId)}
      fallback={Fallback ? <Fallback errorId={errorId} /> : <p>An error has occurred</p>}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
