import React, { FC, SVGProps } from 'react';
import { Slide } from '@mui/material';
import { useAuth } from '@platform/auth';
import { TFunction } from 'react-i18next';
import FooterContent from '../AuthFooterContent/FooterContent';
import { Box } from '@mui/system';
import { NavigateFunction } from 'react-router-dom';

export const NotAuthorized = ({
  t,
  NotAuthorizedImage,
  navigate,
}: {
  t: TFunction<'translation', undefined>;
  NotAuthorizedImage: FC<SVGProps<SVGSVGElement>>;
  navigate: NavigateFunction;
}) => {
  const auth = useAuth();

  const logout = async () => {
    await auth.logout();
    navigate('/');
  };

  return (
    <>
      <Slide
        style={{
          paddingTop: '10vh',
        }}
        direction="up"
        in
        mountOnEnter
        unmountOnExit
      >
        <Box className="flex justify-center">
          <NotAuthorizedImage className="fill-secondary-main w-80" />
        </Box>
      </Slide>
      <Box>
        <FooterContent
          headerText={t('global.no-access-error-heading')}
          helperText={t('global.no-access-error')}
          buttonText={t('global.logout')}
          buttonClick={logout}
        />
      </Box>
    </>
  );
};
