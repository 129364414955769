import React from 'react';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import { ToastTypes, useToast } from '@platform/hooks';
import { CancelRounded, CheckCircle, WarningRounded } from '@mui/icons-material';
import { Trans } from 'react-i18next';

const icon: Record<ToastTypes, JSX.Element> = {
  success: <CheckCircle className="text-success-light" />,
  warning: <CheckCircle className="text-warning-main" />,
  error: <WarningRounded className="text-error-main" />,
  info: <CheckCircle className="text-info-main" />,
};

export const Toast = () => {
  const { state, closeToast } = useToast();

  const message = (
    <div className="flex items-center gap-x-1">
      {icon[state.type || 'info']}
      <span>
        <Trans>{state.message || ''}</Trans>
      </span>
    </div>
  );

  return (
    <Snackbar
      autoHideDuration={state.autoHideDuration}
      open={state.visible}
      onClose={closeToast}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarContent
        className="rounded-xl bg-black flex-nowrap"
        message={message}
        action={
          <IconButton className="text-mui-darkActionActive" size="small" onClick={closeToast}>
            <CancelRounded />
          </IconButton>
        }
      />
    </Snackbar>
  );
};
