import React, { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  ContactSupport,
  LogoutRounded,
  Menu,
  TableRestaurantRounded,
} from '@mui/icons-material';
import ContactSupportModal from '../pages/orders/components/ContactSupportDrawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppBar, DrawerHeader, LayoutStyles, Main } from './Layout.style';
import { useAuth } from '@platform/auth';
import avatar from '../assets/images/avatar.svg';
import {
  logException,
  setUser,
  useBottomDrawer,
  useCompanyCustomisations,
  useTranslation,
} from '@platform/hooks';
import { ROUTE_APP_BAR_VISIBILITY } from '../Routes';
import { LOCALSTORAGE_KEY } from '../pages/configurator/store/configuration';
import { BottomDrawer, Feedback, Modal, OverlayView, PoweredByLendis } from '@platform/ui';
import { useTasks } from '../hooks/useTasks';
import { TASK_STATE } from '@lendis-tech/sdk';
import theme from '../theme';

type Props = {
  children: React.ReactNode;
};

type ListTab = {
  label: string;
  testid: string;
  icon: React.ReactNode;
  action: () => void;
  isVisible: boolean;
};

type User = {
  name: string;
  email: string;
};

const drawerwidth = 320;

export const Layout = ({ children }: Props) => {
  const { t } = useTranslation();
  const currentTheme = useTheme();
  const { isAuthenticated, logout, user } = useAuth();
  const { isLoading, getTaskByType } = useTasks();
  const { showDrawer, state } = useBottomDrawer();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [activeUser, setActiveUser] = React.useState<User>({
    name: '',
    email: '',
  });
  const [open, setOpen] = React.useState(false);
  const { classes, cx } = LayoutStyles();
  const { getCompanySetting } = useCompanyCustomisations();
  const overriddenCompanyLogo = getCompanySetting('logo');

  // Sync user with Sentry
  useEffect(() => {
    setUser({
      username: user?.username,
      customerID: user?.attributes?.['custom:customer_id'],
    });
    if (state.content !== undefined) {
      setOpen(!state.visible);
    }
    // eslint-disable-next-line
  }, [user, state.visible]);

  const showContactSupport = () => {
    setOpen(false);
    showDrawer({
      title: t('customer-support.contact-title'),
      content: <ContactSupportModal />,
    });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const onLogoutClick = () => {
    logout()
      .then(() => {
        setOpen(false);
        localStorage.removeItem(LOCALSTORAGE_KEY);
        navigate('/login');
      })
      .catch((e) => {
        logException(e);
      });
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleNavigate = (path: string) => {
    return navigate(path);
  };

  const VisibleListTabs = useMemo(() => {
    const ListTabs: ListTab[] = [
      {
        label: 'layout.my-workspace',
        testid: 'workspace-btn',
        icon: <TableRestaurantRounded />,
        action: () => handleNavigate('/'),
        isVisible: getTaskByType('profile-invite')?.state === TASK_STATE.IN_PROGRESS,
      },
      {
        label: 'layout.customer-support',
        testid: 'customer-support-btn',
        icon: <ContactSupport />,
        action: () => showContactSupport(),
        isVisible: getTaskByType('profile-invite')?.state === TASK_STATE.IN_PROGRESS,
      },
      {
        label: 'global.logout',
        testid: 'logout-btn',
        icon: <LogoutRounded />,
        action: onLogoutClick,
        isVisible: true,
      },
    ];
    return ListTabs.filter((tab) => tab.isVisible);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTaskByType, isLoading]);
  useEffect(() => {
    if (isAuthenticated && user && user.attributes.email) {
      setActiveUser({
        name: `${user.firstname}  ${user.lastname}`,
        email: user.attributes.email,
      });
    }
  }, [isAuthenticated, user]);
  const ChevIcon = open ? ChevronLeft : ChevronRight;

  return (
    <>
      {isAuthenticated && ROUTE_APP_BAR_VISIBILITY[pathname.split('/')[1]] ? (
        <Box className={classes.container}>
          <AppBar
            sx={{
              boxShadow: 'none',
              position: 'absolute',
            }}
            className={classes.appBar}
            position="fixed"
            open={open}
            drawerwidth={drawerwidth}
          >
            <Toolbar
              sx={{
                boxShadow: 'none',
                position: 'absolute',
              }}
              className={classes.toolBar}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                data-testid="drawer-toggle"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }) }}
              >
                <Menu className={classes.hamburger} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerwidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerwidth,
                boxSizing: 'border-box',
                background: currentTheme.palette.primary.main,
                color: currentTheme.palette.primary.contrastText,
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <ListItemIcon>
                <img className={classes.avatar} src={avatar} alt="avatar" />
              </ListItemIcon>

              <ListItemText
                primary={
                  <>
                    <Typography variant="body1" component="div">
                      {activeUser.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      className="text-dark-text-secondary text-ellipsis overflow-hidden whitespace-nowrap"
                    >
                      {activeUser.email}
                    </Typography>
                  </>
                }
              />
              <IconButton onClick={handleDrawerClose}>
                <ChevIcon className={classes.chevron} />
              </IconButton>
            </DrawerHeader>
            <Divider className="border-primary-contrastText/10" />
            <List
              className={cx(classes.list, { [classes.listFlexEnd]: VisibleListTabs.length === 1 })}
            >
              {VisibleListTabs.map((tab: ListTab) => (
                <ListItem
                  className={tab.label === 'global.logout' ? 'absolute bottom-0' : ''}
                  onClick={tab.action}
                  data-testid={tab.testid}
                  key={tab.label}
                  disablePadding
                >
                  <ListItemButton>
                    <ListItemIcon className="text-primary-contrastText/20">{tab.icon}</ListItemIcon>
                    <ListItemText primary={t(tab.label)} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Drawer>
          <Main open={open} drawerwidth={drawerwidth}>
            <DrawerHeader />
            <div className={classes.content}>
              {children}
              {overriddenCompanyLogo && (
                <div className="pr-10">
                  <PoweredByLendis />
                </div>
              )}
            </div>
          </Main>
        </Box>
      ) : (
        <div
          className="flex flex-col justify-between min-h-screen"
          data-testid="no-header-container"
        >
          {children}
          {overriddenCompanyLogo && (
            <div className="pr-10">
              <PoweredByLendis />
            </div>
          )}
        </div>
      )}
      <Feedback
        theme={theme}
        buttonText={t('feedback.buttonLabel')}
        raiseBugText={t('feedback.reportBugLabel')}
        submitIdeaText={t('feedback.reportIdeaLabel')}
      />
      <Modal />
      <BottomDrawer />
      <OverlayView />
    </>
  );
};
