import { Box, Button, Typography } from '@mui/material';
import { FooterContentStyles } from './auth-footer-content.style';
import React from 'react';

const FooterContent = ({
  headerText,
  helperText,
  buttonText,
  buttonClick,
  optionalhelperTextList = [],
}: {
  headerText: string;
  helperText: React.ReactElement;
  buttonText?: string;
  buttonClick?: () => void;
  optionalhelperTextList?: string[];
}) => {
  const { classes } = FooterContentStyles();
  return (
    <div className={classes.Footer}>
      <div className={classes.Content}>
        <Box
          className={`rounded-xl bg-custom-footerBackground text-white ${classes.FooterContent}`}
        >
          <Typography variant="h6" className="py-2 px-4 text-left">
            {headerText}
          </Typography>
          <Typography variant="subtitle2" className="px-4 pb-4 text-left">
            {helperText}
            {optionalhelperTextList.length > 0 && (
              <ul className="my-1 pl-8">
                {optionalhelperTextList.map((text) => (
                  <li>{text}</li>
                ))}
              </ul>
            )}
          </Typography>
          {buttonText && buttonClick && (
            <Button
              onClick={buttonClick}
              style={{
                width: '95%',
                margin: '9px',
              }}
              variant="contained"
              color="secondary"
            >
              {buttonText}
            </Button>
          )}
        </Box>
      </div>
    </div>
  );
};

export default FooterContent;
