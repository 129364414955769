import { useAuth } from '@platform/auth';
import React from 'react';
import { useQuery } from 'react-query';

type AutologinHandlerProps = {
  token: string;
  onError?: (error: Error) => void;
  useErrorBoundary?: boolean;
};

export const AutologinHandler: React.FC<AutologinHandlerProps> = (props) => {
  const { autologin } = useAuth();

  useQuery('autologin', () => autologin(props.token), {
    suspense: true,
    cacheTime: Infinity,
    onError: props.onError,
    useErrorBoundary: props.useErrorBoundary,
  });

  return <>{props.children}</>;
};
