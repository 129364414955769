import { Link } from '@mui/material';
import { Trans, useTranslation } from '@platform/hooks';
import { PageNotFound } from '@platform/ui';
import { useNavigate } from 'react-router-dom';

const ErrorMessage: React.FC<{ errorId?: string }> = ({ errorId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <PageNotFound
      title={t('page-not-found.title')}
      message={
        <>
          <Trans i18nKey={'page-not-found.error-message'}>
            <Link
              component="button"
              color="info.light"
              underline="hover"
              onClick={() => {
                if (window.JIRA_COLLECTORS?.bug) {
                  window.JIRA_COLLECTORS.bug();
                } else {
                  document.location.href = 'mailto:support@lendis.de';
                }
              }}
            />
          </Trans>
          <div className="mt-2">{t('page-not-found.error-code', { errorId })}</div>
        </>
      }
      buttonLabel={t('page-not-found.back-to-home')}
      onButtonClick={() => navigate('/')}
    />
  );
};

export default ErrorMessage;
