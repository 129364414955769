import { OrderLineItemV2, OrderStates, TASK_STATE } from '@lendis-tech/sdk';
import {
  ActiveEmployeesAggregation,
  CompanySettings,
  CreateBatchTaskPayload,
  CreateOrderPayload,
  CreateSupportTicketResponse,
  CustomerSatisfactionRequest,
  CustomerSatisfactionSubmitRequest,
  GetSupportTicketInfoResponse,
  Invite,
  OrderPayload,
  OrderType,
  PaginatedResponse,
  ProfileProductsResponse,
  ProfileRequest,
  ProfileResponse,
  SearchRequest,
  SupportTicketPayload,
  TaskPayload,
  UploadLogoPayload,
} from '@platform/api';

import { DomainPortal, FakeStorage, useAlgolia } from '@platform/hooks';
import { getEnv } from '../utils/helpers';

const profilesByID = {
  'PROFILE-2022-2822': {
    stats: {
      totalEmployees: ['T-2022-00012', 'T-2022-00158', 'T-2022-00162', 'T-2022-00253'],
      activeInvites: ['T-2022-00162', 'T-2022-00253'],
      inDeliveryOrders: ['O-2022-00052'],
      fulfilledOrders: [],
    },
    costs: { deliveryMax: 11800, monthlyMax: 15535, monthlyMin: 0 },
    _id: '631b40c587bce163173672cb',
    profileId: 'PROFILE-2022-2822',
    name: 'Untitled Group',
    customerId: 'K-10269',
    isActive: true,
    rentalPeriod: 36,
    createdAt: '2022-09-09T13:33:57.815Z',
    modifiedAt: '2022-10-17T13:48:46.927Z',
    products: [
      {
        name: { 'de-DE': 'Arbeitsleuchte Matt', 'en-US': 'Work Lamp Matt' },
        description: {
          'de-DE': 'LED, stufenlos dimmbar, dreh- und schwenkbarer Leuchtkopf, Ausrichtbare Arme',
          'en-US': 'Dimension: 31 x 20 x 35 cm (LxWxH), Colour: Black, Temp.: 3000K',
        },
        losVertical: {
          name: { 'de-DE': 'Furniture', 'en-US': 'Furniture' },
          slug: 'furniture_los',
        },
        losCategory: { name: { 'de-DE': 'Lighting', 'en-US': 'Lighting' }, slug: 'lighting_los' },
        lendis_id: 'FN-0000000768',
        vertical_slug: 'furniture',
        category_slug: 'furniture_lighting',
        delivery_weeks: 2,
        delivery_type: 'third-party',
        rental_period: 36,
        quantity: 1,
        rate: 7.58,
        rates: [
          { duration: 6, price: 30.39, _id: '631b40e187bce163173672d1' },
          { duration: 7, price: 26.69, _id: '631b40e187bce163173672d2' },
          { duration: 8, price: 23.82, _id: '631b40e187bce163173672d3' },
          { duration: 9, price: 21.59, _id: '631b40e187bce163173672d4' },
          { duration: 10, price: 19.79, _id: '631b40e187bce163173672d5' },
          { duration: 11, price: 18.32, _id: '631b40e187bce163173672d6' },
          { duration: 12, price: 17.37, _id: '631b40e187bce163173672d7' },
          { duration: 13, price: 16.38, _id: '631b40e187bce163173672d8' },
          { duration: 14, price: 15.54, _id: '631b40e187bce163173672d9' },
          { duration: 15, price: 14.81, _id: '631b40e187bce163173672da' },
          { duration: 16, price: 14.17, _id: '631b40e187bce163173672db' },
          { duration: 17, price: 13.61, _id: '631b40e187bce163173672dc' },
          { duration: 18, price: 13.05, _id: '631b40e187bce163173672dd' },
          { duration: 19, price: 12.47, _id: '631b40e187bce163173672de' },
          { duration: 20, price: 11.95, _id: '631b40e187bce163173672df' },
          { duration: 21, price: 11.48, _id: '631b40e187bce163173672e0' },
          { duration: 22, price: 11.05, _id: '631b40e187bce163173672e1' },
          { duration: 23, price: 10.66, _id: '631b40e187bce163173672e2' },
          { duration: 24, price: 10.28, _id: '631b40e187bce163173672e3' },
          { duration: 25, price: 9.96, _id: '631b40e187bce163173672e4' },
          { duration: 26, price: 9.67, _id: '631b40e187bce163173672e5' },
          { duration: 27, price: 9.4, _id: '631b40e187bce163173672e6' },
          { duration: 28, price: 9.14, _id: '631b40e187bce163173672e7' },
          { duration: 29, price: 8.9, _id: '631b40e187bce163173672e8' },
          { duration: 30, price: 8.68, _id: '631b40e187bce163173672e9' },
          { duration: 31, price: 8.47, _id: '631b40e187bce163173672ea' },
          { duration: 32, price: 8.27, _id: '631b40e187bce163173672eb' },
          { duration: 33, price: 8.09, _id: '631b40e187bce163173672ec' },
          { duration: 34, price: 7.91, _id: '631b40e187bce163173672ed' },
          { duration: 35, price: 7.74, _id: '631b40e187bce163173672ee' },
          { duration: 36, price: 7.58, _id: '631b40e187bce163173672ef' },
          { duration: 37, price: 7.44, _id: '631b40e187bce163173672f0' },
          { duration: 38, price: 7.31, _id: '631b40e187bce163173672f1' },
          { duration: 39, price: 7.19, _id: '631b40e187bce163173672f2' },
          { duration: 40, price: 7.07, _id: '631b40e187bce163173672f3' },
          { duration: 41, price: 6.95, _id: '631b40e187bce163173672f4' },
          { duration: 42, price: 6.84, _id: '631b40e187bce163173672f5' },
          { duration: 43, price: 6.73, _id: '631b40e187bce163173672f6' },
          { duration: 44, price: 6.63, _id: '631b40e187bce163173672f7' },
          { duration: 45, price: 6.53, _id: '631b40e187bce163173672f8' },
          { duration: 46, price: 6.44, _id: '631b40e187bce163173672f9' },
          { duration: 47, price: 6.34, _id: '631b40e187bce163173672fa' },
          { duration: 48, price: 6.26, _id: '631b40e187bce163173672fb' },
          { duration: 49, price: 6.17, _id: '631b40e187bce163173672fc' },
          { duration: 50, price: 6.09, _id: '631b40e187bce163173672fd' },
          { duration: 51, price: 6.01, _id: '631b40e187bce163173672fe' },
          { duration: 52, price: 5.93, _id: '631b40e187bce163173672ff' },
          { duration: 53, price: 5.86, _id: '631b40e187bce16317367300' },
          { duration: 54, price: 5.78, _id: '631b40e187bce16317367301' },
          { duration: 55, price: 5.71, _id: '631b40e187bce16317367302' },
          { duration: 56, price: 5.64, _id: '631b40e187bce16317367303' },
          { duration: 57, price: 5.58, _id: '631b40e187bce16317367304' },
          { duration: 58, price: 5.51, _id: '631b40e187bce16317367305' },
          { duration: 59, price: 5.45, _id: '631b40e187bce16317367306' },
          { duration: 60, price: 5.38, _id: '631b40e187bce16317367307' },
        ],
        images: [
          {
            url: 'M%C3%B6bel/Beleuchtung/FN-0000000768/fn-0000000768-lamp-black',
            id: null,
            _id: '631b40e187bce16317367308',
          },
          {
            url: 'M%C3%B6bel/Beleuchtung/FN-0000000768/Artemide_Demetra_Professional_Table_Matt_Black_ot20jx',
            id: null,
            _id: '631b40e187bce16317367309',
          },
        ],
        _id: '631b40e187bce163173672d0',
      },
      {
        name: { 'de-DE': 'Motor-Schreibtisch Ryan', 'en-US': 'Electric Desk Ryan' },
        description: {
          'de-DE': 'elektrisch höhenverstellbar, inkl. Kabelmanagement',
          'en-US': 'Dimension: 160 x 80 x 72-120 cm (WxDxH), Colour: Amber Oak',
        },
        losVertical: {
          name: { 'de-DE': 'Furniture', 'en-US': 'Furniture' },
          slug: 'furniture_los',
        },
        losCategory: { name: { 'de-DE': 'Tables', 'en-US': 'Tables' }, slug: 'tables_los' },
        lendis_id: 'FN-0000000755',
        vertical_slug: 'furniture',
        category_slug: 'furniture_tables',
        delivery_weeks: 2,
        delivery_type: 'third-party',
        rental_period: 36,
        quantity: 1,
        rate: 17.05,
        rates: [
          { duration: 6, price: 68.32, _id: '631b40e187bce1631736730b' },
          { duration: 7, price: 60.01, _id: '631b40e187bce1631736730c' },
          { duration: 8, price: 53.55, _id: '631b40e187bce1631736730d' },
          { duration: 9, price: 48.52, _id: '631b40e187bce1631736730e' },
          { duration: 10, price: 44.49, _id: '631b40e187bce1631736730f' },
          { duration: 11, price: 41.18, _id: '631b40e187bce16317367310' },
          { duration: 12, price: 39.04, _id: '631b40e187bce16317367311' },
          { duration: 13, price: 36.83, _id: '631b40e187bce16317367312' },
          { duration: 14, price: 34.94, _id: '631b40e187bce16317367313' },
          { duration: 15, price: 33.3, _id: '631b40e187bce16317367314' },
          { duration: 16, price: 31.86, _id: '631b40e187bce16317367315' },
          { duration: 17, price: 30.59, _id: '631b40e187bce16317367316' },
          { duration: 18, price: 29.35, _id: '631b40e187bce16317367317' },
          { duration: 19, price: 28.04, _id: '631b40e187bce16317367318' },
          { duration: 20, price: 26.87, _id: '631b40e187bce16317367319' },
          { duration: 21, price: 25.8, _id: '631b40e187bce1631736731a' },
          { duration: 22, price: 24.84, _id: '631b40e187bce1631736731b' },
          { duration: 23, price: 23.96, _id: '631b40e187bce1631736731c' },
          { duration: 24, price: 23.11, _id: '631b40e187bce1631736731d' },
          { duration: 25, price: 22.4, _id: '631b40e187bce1631736731e' },
          { duration: 26, price: 21.74, _id: '631b40e187bce1631736731f' },
          { duration: 27, price: 21.12, _id: '631b40e187bce16317367320' },
          { duration: 28, price: 20.55, _id: '631b40e187bce16317367321' },
          { duration: 29, price: 20.02, _id: '631b40e187bce16317367322' },
          { duration: 30, price: 19.51, _id: '631b40e187bce16317367323' },
          { duration: 31, price: 19.04, _id: '631b40e187bce16317367324' },
          { duration: 32, price: 18.6, _id: '631b40e187bce16317367325' },
          { duration: 33, price: 18.18, _id: '631b40e187bce16317367326' },
          { duration: 34, price: 17.78, _id: '631b40e187bce16317367327' },
          { duration: 35, price: 17.41, _id: '631b40e187bce16317367328' },
          { duration: 36, price: 17.05, _id: '631b40e187bce16317367329' },
          { duration: 37, price: 16.74, _id: '631b40e187bce1631736732a' },
          { duration: 38, price: 16.44, _id: '631b40e187bce1631736732b' },
          { duration: 39, price: 16.15, _id: '631b40e187bce1631736732c' },
          { duration: 40, price: 15.88, _id: '631b40e187bce1631736732d' },
          { duration: 41, price: 15.62, _id: '631b40e187bce1631736732e' },
          { duration: 42, price: 15.37, _id: '631b40e187bce1631736732f' },
          { duration: 43, price: 15.13, _id: '631b40e187bce16317367330' },
          { duration: 44, price: 14.9, _id: '631b40e187bce16317367331' },
          { duration: 45, price: 14.68, _id: '631b40e187bce16317367332' },
          { duration: 46, price: 14.47, _id: '631b40e187bce16317367333' },
          { duration: 47, price: 14.26, _id: '631b40e187bce16317367334' },
          { duration: 48, price: 14.06, _id: '631b40e187bce16317367335' },
          { duration: 49, price: 13.87, _id: '631b40e187bce16317367336' },
          { duration: 50, price: 13.69, _id: '631b40e187bce16317367337' },
          { duration: 51, price: 13.51, _id: '631b40e187bce16317367338' },
          { duration: 52, price: 13.33, _id: '631b40e187bce16317367339' },
          { duration: 53, price: 13.16, _id: '631b40e187bce1631736733a' },
          { duration: 54, price: 13, _id: '631b40e187bce1631736733b' },
          { duration: 55, price: 12.84, _id: '631b40e187bce1631736733c' },
          { duration: 56, price: 12.69, _id: '631b40e187bce1631736733d' },
          { duration: 57, price: 12.54, _id: '631b40e187bce1631736733e' },
          { duration: 58, price: 12.39, _id: '631b40e187bce1631736733f' },
          { duration: 59, price: 12.25, _id: '631b40e187bce16317367340' },
          { duration: 60, price: 12.1, _id: '631b40e187bce16317367341' },
        ],
        images: [
          {
            url: 'M%C3%B6bel/Tische/FN-0000000753/fn-0000000753-ryan-brown',
            id: null,
            _id: '631b40e187bce16317367342',
          },
          {
            url: 'M%C3%B6bel/Tische/FN-0000000755/20-0146434_oum9nc',
            id: null,
            _id: '631b40e187bce16317367343',
          },
        ],
        _id: '631b40e187bce1631736730a',
      },
      {
        name: {
          'de-DE':
            'Apple 16" MacBook Pro - Apple M1 Pro, 10-core CPU, 16-core Graphics, 16 GB RAM, 512 GB SSD, QWERTY (EN INT)',
          'en-US': '16" MacBook Pro (EN)',
        },
        description: {
          'de-DE': 'Größe (GB): 16, Größe (GB): 512, Tastatur Layout: QWERTY (Int. EN)',
          'en-US': 'M1 Pro, 16 GB RAM, 512 GB SSD, 16-Core GPU, Space Grey, QWERTY',
        },
        losVertical: { name: { 'de-DE': 'Laptops', 'en-US': 'Laptops' }, slug: 'laptops_los' },
        lendis_id: 'EL-0000001162',
        vertical_slug: 'electronics',
        category_slug: 'electronics_laptops',
        delivery_weeks: 8,
        delivery_type: 'drop-shipping',
        rental_period: 36,
        quantity: 1,
        rate: 77.41,
        rates: [
          { duration: 6, price: 223.69, _id: '631b40e187bce16317367345' },
          { duration: 7, price: 201.21, _id: '631b40e187bce16317367346' },
          { duration: 8, price: 184.24, _id: '631b40e187bce16317367347' },
          { duration: 9, price: 170.96, _id: '631b40e187bce16317367348' },
          { duration: 10, price: 160.25, _id: '631b40e187bce16317367349' },
          { duration: 11, price: 151.41, _id: '631b40e187bce1631736734a' },
          { duration: 12, price: 143.98, _id: '631b40e187bce1631736734b' },
          { duration: 13, price: 136.71, _id: '631b40e187bce1631736734c' },
          { duration: 14, price: 130.43, _id: '631b40e187bce1631736734d' },
          { duration: 15, price: 124.96, _id: '631b40e187bce1631736734e' },
          { duration: 16, price: 120.13, _id: '631b40e187bce1631736734f' },
          { duration: 17, price: 115.84, _id: '631b40e187bce16317367350' },
          { duration: 18, price: 111.99, _id: '631b40e187bce16317367351' },
          { duration: 19, price: 107.83, _id: '631b40e187bce16317367352' },
          { duration: 20, price: 104.07, _id: '631b40e187bce16317367353' },
          { duration: 21, price: 100.65, _id: '631b40e187bce16317367354' },
          { duration: 22, price: 97.53, _id: '631b40e187bce16317367355' },
          { duration: 23, price: 94.66, _id: '631b40e187bce16317367356' },
          { duration: 24, price: 92.02, _id: '631b40e187bce16317367357' },
          { duration: 25, price: 90.66, _id: '631b40e187bce16317367358' },
          { duration: 26, price: 89.43, _id: '631b40e187bce16317367359' },
          { duration: 27, price: 88.32, _id: '631b40e187bce1631736735a' },
          { duration: 28, price: 87.32, _id: '631b40e187bce1631736735b' },
          { duration: 29, price: 86.41, _id: '631b40e187bce1631736735c' },
          { duration: 30, price: 84.88, _id: '631b40e187bce1631736735d' },
          { duration: 31, price: 83.47, _id: '631b40e187bce1631736735e' },
          { duration: 32, price: 82.16, _id: '631b40e187bce1631736735f' },
          { duration: 33, price: 80.95, _id: '631b40e187bce16317367360' },
          { duration: 34, price: 79.82, _id: '631b40e187bce16317367361' },
          { duration: 35, price: 78.78, _id: '631b40e187bce16317367362' },
          { duration: 36, price: 77.41, _id: '631b40e187bce16317367363' },
        ],
        images: [
          {
            url: 'Elektronik/Laptops/EL-0000001044/el-0000001044-apple-macbook-pro-m1',
            id: null,
            _id: '631b40e187bce16317367364',
          },
          {
            url: 'Elektronik/Laptops/EL-0000001161/mbp16-spacegray-select-202110_tv1ojm',
            id: null,
            _id: '631b40e187bce16317367365',
          },
        ],
        _id: '631b40e187bce16317367344',
      },
      {
        name: { 'de-DE': 'LG - 27UP850-W - 27" (4K, IPS, USB-C)', 'en-US': "27'' LG 4K Monitor" },
        description: {
          'de-DE':
            'Größe ("): 27, Auflösung: 3840 x 2160, Bildformat: 16:9, Reaktionszeit (ms): 5, Kontrastverhältnis: 1200:1',
          'en-US': 'IPS, Height-Adjustable, Pivot, DisplayPort, HDMI, USB-C',
        },
        losVertical: { name: { 'de-DE': 'Monitors', 'en-US': 'Monitors' }, slug: 'monitors_los' },
        lendis_id: 'EL-0000001155',
        vertical_slug: 'electronics',
        category_slug: 'electronics_monitors',
        delivery_weeks: 6,
        delivery_type: 'drop-shipping',
        rental_period: 36,
        quantity: 1,
        rate: 18.2,
        rates: [
          { duration: 6, price: 49.31, _id: '631b40e187bce16317367367' },
          { duration: 7, price: 44.43, _id: '631b40e187bce16317367368' },
          { duration: 8, price: 40.75, _id: '631b40e187bce16317367369' },
          { duration: 9, price: 37.87, _id: '631b40e187bce1631736736a' },
          { duration: 10, price: 35.55, _id: '631b40e187bce1631736736b' },
          { duration: 11, price: 33.64, _id: '631b40e187bce1631736736c' },
          { duration: 12, price: 32.03, _id: '631b40e187bce1631736736d' },
          { duration: 13, price: 30.65, _id: '631b40e187bce1631736736e' },
          { duration: 14, price: 29.46, _id: '631b40e187bce1631736736f' },
          { duration: 15, price: 28.42, _id: '631b40e187bce16317367370' },
          { duration: 16, price: 27.5, _id: '631b40e187bce16317367371' },
          { duration: 17, price: 26.68, _id: '631b40e187bce16317367372' },
          { duration: 18, price: 25.94, _id: '631b40e187bce16317367373' },
          { duration: 19, price: 25.27, _id: '631b40e187bce16317367374' },
          { duration: 20, price: 24.66, _id: '631b40e187bce16317367375' },
          { duration: 21, price: 24.1, _id: '631b40e187bce16317367376' },
          { duration: 22, price: 23.58, _id: '631b40e187bce16317367377' },
          { duration: 23, price: 23.11, _id: '631b40e187bce16317367378' },
          { duration: 24, price: 22.64, _id: '631b40e187bce16317367379' },
          { duration: 25, price: 22.13, _id: '631b40e187bce1631736737a' },
          { duration: 26, price: 21.65, _id: '631b40e187bce1631736737b' },
          { duration: 27, price: 21.21, _id: '631b40e187bce1631736737c' },
          { duration: 28, price: 20.79, _id: '631b40e187bce1631736737d' },
          { duration: 29, price: 20.4, _id: '631b40e187bce1631736737e' },
          { duration: 30, price: 20.03, _id: '631b40e187bce1631736737f' },
          { duration: 31, price: 19.68, _id: '631b40e187bce16317367380' },
          { duration: 32, price: 19.36, _id: '631b40e187bce16317367381' },
          { duration: 33, price: 19.04, _id: '631b40e187bce16317367382' },
          { duration: 34, price: 18.75, _id: '631b40e187bce16317367383' },
          { duration: 35, price: 18.47, _id: '631b40e187bce16317367384' },
          { duration: 36, price: 18.2, _id: '631b40e187bce16317367385' },
        ],
        images: [
          {
            url: 'Elektronik/Monitore/EL-0000001155/el-0000001155-lg-monitor',
            id: null,
            _id: '631b40e187bce16317367386',
          },
          {
            url: 'Elektronik/Monitore/E-00000038/monitore-dell-ultrasharp-U2417H_hyaf48',
            id: null,
            _id: '631b40e187bce16317367387',
          },
        ],
        _id: '631b40e187bce16317367366',
      },
      {
        name: { 'de-DE': "Dell 27'' FHD Monitor (P2722HE)", 'en-US': "27'' Dell FHD Monitor" },
        description: {
          'de-DE':
            'Größe ("): 27, Auflösung: 1920x1080, Panel: LED-Hintergrundbeleuchtung, Bildformat: FHD, Reaktionszeit (ms): 8, Kontrastverhältnis: 1000:1',
          'en-US': 'IPS, Height-Adjustable, Pivot, DisplayPort, HDMI, USB-C',
        },
        losVertical: { name: { 'de-DE': 'Monitors', 'en-US': 'Monitors' }, slug: 'monitors_los' },
        lendis_id: 'EL-0000001022',
        vertical_slug: 'electronics',
        category_slug: 'electronics_monitors',
        delivery_weeks: 2,
        delivery_type: 'drop-shipping',
        rental_period: 36,
        quantity: 1,
        rate: 13.68,
        rates: [
          { duration: 6, price: 35.77, _id: '631b40e187bce16317367389' },
          { duration: 7, price: 32.31, _id: '631b40e187bce1631736738a' },
          { duration: 8, price: 29.7, _id: '631b40e187bce1631736738b' },
          { duration: 9, price: 27.66, _id: '631b40e187bce1631736738c' },
          { duration: 10, price: 26.01, _id: '631b40e187bce1631736738d' },
          { duration: 11, price: 24.65, _id: '631b40e187bce1631736738e' },
          { duration: 12, price: 23.51, _id: '631b40e187bce1631736738f' },
          { duration: 13, price: 22.53, _id: '631b40e187bce16317367390' },
          { duration: 14, price: 21.68, _id: '631b40e187bce16317367391' },
          { duration: 15, price: 20.94, _id: '631b40e187bce16317367392' },
          { duration: 16, price: 20.29, _id: '631b40e187bce16317367393' },
          { duration: 17, price: 19.71, _id: '631b40e187bce16317367394' },
          { duration: 18, price: 19.18, _id: '631b40e187bce16317367395' },
          { duration: 19, price: 18.71, _id: '631b40e187bce16317367396' },
          { duration: 20, price: 18.27, _id: '631b40e187bce16317367397' },
          { duration: 21, price: 17.87, _id: '631b40e187bce16317367398' },
          { duration: 22, price: 17.51, _id: '631b40e187bce16317367399' },
          { duration: 23, price: 17.17, _id: '631b40e187bce1631736739a' },
          { duration: 24, price: 16.84, _id: '631b40e187bce1631736739b' },
          { duration: 25, price: 16.48, _id: '631b40e187bce1631736739c' },
          { duration: 26, price: 16.14, _id: '631b40e187bce1631736739d' },
          { duration: 27, price: 15.82, _id: '631b40e187bce1631736739e' },
          { duration: 28, price: 15.53, _id: '631b40e187bce1631736739f' },
          { duration: 29, price: 15.25, _id: '631b40e187bce163173673a0' },
          { duration: 30, price: 14.99, _id: '631b40e187bce163173673a1' },
          { duration: 31, price: 14.74, _id: '631b40e187bce163173673a2' },
          { duration: 32, price: 14.51, _id: '631b40e187bce163173673a3' },
          { duration: 33, price: 14.29, _id: '631b40e187bce163173673a4' },
          { duration: 34, price: 14.08, _id: '631b40e187bce163173673a5' },
          { duration: 35, price: 13.88, _id: '631b40e187bce163173673a6' },
          { duration: 36, price: 13.68, _id: '631b40e187bce163173673a7' },
        ],
        images: [
          {
            url: 'Elektronik/Monitore/EL-0000001022/el-0000001022-dell-monitor',
            id: null,
            _id: '631b40e187bce163173673a8',
          },
          {
            url: 'Elektronik/Monitore/E-00000038/monitore-dell-ultrasharp-U2417H_hyaf48',
            id: null,
            _id: '631b40e187bce163173673a9',
          },
        ],
        _id: '631b40e187bce16317367388',
      },
      {
        name: { 'de-DE': 'Logitech MX Master 3 Ergonomische Maus', 'en-US': 'Logitech  Mouse' },
        description: {
          'de-DE': '7 Tasten Bluetooth-Maus, Daumenrad, Scrollrad, 2,4GHz',
          'en-US': 'Ergonomic Mouse',
        },
        losVertical: {
          name: { 'de-DE': 'Accessories', 'en-US': 'Accessories' },
          slug: 'accessories_los',
        },
        losCategory: { name: { 'de-DE': 'Mouse', 'en-US': 'Mouse' }, slug: 'mouse_los' },
        lendis_id: 'EL-0000000810',
        vertical_slug: 'electronics',
        category_slug: 'electronics_desktops',
        delivery_weeks: 2,
        delivery_type: 'drop-shipping',
        rental_period: 36,
        quantity: 1,
        rate: 3.16,
        rates: [
          { duration: 6, price: 8.21, _id: '631b40e187bce163173673ab' },
          { duration: 7, price: 7.42, _id: '631b40e187bce163173673ac' },
          { duration: 8, price: 6.82, _id: '631b40e187bce163173673ad' },
          { duration: 9, price: 6.36, _id: '631b40e187bce163173673ae' },
          { duration: 10, price: 5.98, _id: '631b40e187bce163173673af' },
          { duration: 11, price: 5.67, _id: '631b40e187bce163173673b0' },
          { duration: 12, price: 5.41, _id: '631b40e187bce163173673b1' },
          { duration: 13, price: 5.18, _id: '631b40e187bce163173673b2' },
          { duration: 14, price: 4.99, _id: '631b40e187bce163173673b3' },
          { duration: 15, price: 4.82, _id: '631b40e187bce163173673b4' },
          { duration: 16, price: 4.67, _id: '631b40e187bce163173673b5' },
          { duration: 17, price: 4.54, _id: '631b40e187bce163173673b6' },
          { duration: 18, price: 4.42, _id: '631b40e187bce163173673b7' },
          { duration: 19, price: 4.31, _id: '631b40e187bce163173673b8' },
          { duration: 20, price: 4.21, _id: '631b40e187bce163173673b9' },
          { duration: 21, price: 4.12, _id: '631b40e187bce163173673ba' },
          { duration: 22, price: 4.03, _id: '631b40e187bce163173673bb' },
          { duration: 23, price: 3.96, _id: '631b40e187bce163173673bc' },
          { duration: 24, price: 3.88, _id: '631b40e187bce163173673bd' },
          { duration: 25, price: 3.8, _id: '631b40e187bce163173673be' },
          { duration: 26, price: 3.72, _id: '631b40e187bce163173673bf' },
          { duration: 27, price: 3.65, _id: '631b40e187bce163173673c0' },
          { duration: 28, price: 3.58, _id: '631b40e187bce163173673c1' },
          { duration: 29, price: 3.52, _id: '631b40e187bce163173673c2' },
          { duration: 30, price: 3.46, _id: '631b40e187bce163173673c3' },
          { duration: 31, price: 3.4, _id: '631b40e187bce163173673c4' },
          { duration: 32, price: 3.35, _id: '631b40e187bce163173673c5' },
          { duration: 33, price: 3.3, _id: '631b40e187bce163173673c6' },
          { duration: 34, price: 3.25, _id: '631b40e187bce163173673c7' },
          { duration: 35, price: 3.2, _id: '631b40e187bce163173673c8' },
          { duration: 36, price: 3.16, _id: '631b40e187bce163173673c9' },
        ],
        images: [
          {
            url: 'Elektronik/Kabel%20-%20Zubeh%C3%B6r/EL-0000000810/el-0000000810-logitech-maus-mxmaster',
            id: null,
            _id: '631b40e187bce163173673ca',
          },
        ],
        _id: '631b40e187bce163173673aa',
      },
      {
        name: {
          'de-DE': 'Logitech MX Keys Kabellos Tastatur (US)',
          'en-US': 'Logitech Keyboard (EN)',
        },
        description: {
          'de-DE': 'Nummernblock, Hintergrundbeleuchtet, Wiederaufladbar',
          'en-US': 'MX Keys, Wireless',
        },
        losVertical: {
          name: { 'de-DE': 'Accessories', 'en-US': 'Accessories' },
          slug: 'accessories_los',
        },
        losCategory: { name: { 'de-DE': 'Keyboard', 'en-US': 'Keyboard' }, slug: 'keyboard_los' },
        lendis_id: 'EL-0000000779',
        vertical_slug: 'electronics',
        category_slug: 'electronics_desktops',
        delivery_weeks: 2,
        delivery_type: 'drop-shipping',
        rental_period: 36,
        quantity: 1,
        rate: 4.52,
        rates: [
          { duration: 6, price: 12.3, _id: '631b40e187bce163173673cc' },
          { duration: 7, price: 11.08, _id: '631b40e187bce163173673cd' },
          { duration: 8, price: 10.16, _id: '631b40e187bce163173673ce' },
          { duration: 9, price: 9.44, _id: '631b40e187bce163173673cf' },
          { duration: 10, price: 8.86, _id: '631b40e187bce163173673d0' },
          { duration: 11, price: 8.38, _id: '631b40e187bce163173673d1' },
          { duration: 12, price: 7.98, _id: '631b40e187bce163173673d2' },
          { duration: 13, price: 7.63, _id: '631b40e187bce163173673d3' },
          { duration: 14, price: 7.34, _id: '631b40e187bce163173673d4' },
          { duration: 15, price: 7.08, _id: '631b40e187bce163173673d5' },
          { duration: 16, price: 6.84, _id: '631b40e187bce163173673d6' },
          { duration: 17, price: 6.64, _id: '631b40e187bce163173673d7' },
          { duration: 18, price: 6.45, _id: '631b40e187bce163173673d8' },
          { duration: 19, price: 6.29, _id: '631b40e187bce163173673d9' },
          { duration: 20, price: 6.13, _id: '631b40e187bce163173673da' },
          { duration: 21, price: 5.99, _id: '631b40e187bce163173673db' },
          { duration: 22, price: 5.87, _id: '631b40e187bce163173673dc' },
          { duration: 23, price: 5.75, _id: '631b40e187bce163173673dd' },
          { duration: 24, price: 5.63, _id: '631b40e187bce163173673de' },
          { duration: 25, price: 5.5, _id: '631b40e187bce163173673df' },
          { duration: 26, price: 5.38, _id: '631b40e187bce163173673e0' },
          { duration: 27, price: 5.27, _id: '631b40e187bce163173673e1' },
          { duration: 28, price: 5.17, _id: '631b40e187bce163173673e2' },
          { duration: 29, price: 5.07, _id: '631b40e187bce163173673e3' },
          { duration: 30, price: 4.98, _id: '631b40e187bce163173673e4' },
          { duration: 31, price: 4.89, _id: '631b40e187bce163173673e5' },
          { duration: 32, price: 4.81, _id: '631b40e187bce163173673e6' },
          { duration: 33, price: 4.73, _id: '631b40e187bce163173673e7' },
          { duration: 34, price: 4.66, _id: '631b40e187bce163173673e8' },
          { duration: 35, price: 4.59, _id: '631b40e187bce163173673e9' },
          { duration: 36, price: 4.52, _id: '631b40e187bce163173673ea' },
        ],
        images: [
          {
            url: 'Elektronik/Kabel%20-%20Zubeh%C3%B6r/EL-0000000778/el-0000000778-logitech-kabellos-maus',
            id: null,
            _id: '631b40e187bce163173673eb',
          },
        ],
        _id: '631b40e187bce163173673cb',
      },
      {
        name: { 'de-DE': 'Apple iPhone 13 5G - 128 GB - Mitternacht', 'en-US': 'iPhone 13' },
        description: {
          'de-DE':
            'Größe ("): 6.1, Typ: Super Retina XDR OLED, Speicher (GB): 128, Farbe: Mitternacht, Biometrische Sicherheit: Face ID, Hauptkamera (MP): 12, SIM Karte: Ja',
          'en-US': '5G, 128 GB, Midnight',
        },
        losVertical: { name: { 'de-DE': 'Phones', 'en-US': 'Phones' }, slug: 'phones_los' },
        lendis_id: 'EL-0000000900',
        vertical_slug: 'electronics',
        category_slug: 'electronics_smartphones',
        delivery_weeks: 3,
        delivery_type: 'drop-shipping',
        rental_period: 36,
        quantity: 1,
        rate: 27.43,
        rates: [
          { duration: 6, price: 78.9, _id: '631b40e187bce163173673ed' },
          { duration: 7, price: 70.99, _id: '631b40e187bce163173673ee' },
          { duration: 8, price: 65.02, _id: '631b40e187bce163173673ef' },
          { duration: 9, price: 60.34, _id: '631b40e187bce163173673f0' },
          { duration: 10, price: 56.58, _id: '631b40e187bce163173673f1' },
          { duration: 11, price: 53.47, _id: '631b40e187bce163173673f2' },
          { duration: 12, price: 50.85, _id: '631b40e187bce163173673f3' },
          { duration: 13, price: 48.3, _id: '631b40e187bce163173673f4' },
          { duration: 14, price: 46.09, _id: '631b40e187bce163173673f5' },
          { duration: 15, price: 44.16, _id: '631b40e187bce163173673f6' },
          { duration: 16, price: 42.46, _id: '631b40e187bce163173673f7' },
          { duration: 17, price: 40.95, _id: '631b40e187bce163173673f8' },
          { duration: 18, price: 39.6, _id: '631b40e187bce163173673f9' },
          { duration: 19, price: 38.14, _id: '631b40e187bce163173673fa' },
          { duration: 20, price: 36.81, _id: '631b40e187bce163173673fb' },
          { duration: 21, price: 35.61, _id: '631b40e187bce163173673fc' },
          { duration: 22, price: 34.51, _id: '631b40e187bce163173673fd' },
          { duration: 23, price: 33.5, _id: '631b40e187bce163173673fe' },
          { duration: 24, price: 32.57, _id: '631b40e187bce163173673ff' },
          { duration: 25, price: 32.1, _id: '631b40e187bce16317367400' },
          { duration: 26, price: 31.66, _id: '631b40e187bce16317367401' },
          { duration: 27, price: 31.27, _id: '631b40e187bce16317367402' },
          { duration: 28, price: 30.92, _id: '631b40e187bce16317367403' },
          { duration: 29, price: 30.6, _id: '631b40e187bce16317367404' },
          { duration: 30, price: 30.06, _id: '631b40e187bce16317367405' },
          { duration: 31, price: 29.57, _id: '631b40e187bce16317367406' },
          { duration: 32, price: 29.11, _id: '631b40e187bce16317367407' },
          { duration: 33, price: 28.68, _id: '631b40e187bce16317367408' },
          { duration: 34, price: 28.28, _id: '631b40e187bce16317367409' },
          { duration: 35, price: 27.92, _id: '631b40e187bce1631736740a' },
          { duration: 36, price: 27.43, _id: '631b40e187bce1631736740b' },
        ],
        images: [
          {
            url: 'Elektronik/Smartphones/EL-0000000900/el-0000000900-apple-iphone-13',
            id: null,
            _id: '631b40e187bce1631736740c',
          },
        ],
        _id: '631b40e187bce163173673ec',
      },
    ],
    rules: [],
    __v: 0,
  },
};

const domainPortal = new DomainPortal({
  url: document.location.hostname.includes('amplifyapp.com')
    ? prompt(
        'Seems like you are testing using Amplify, please type the Admin App URL starting from https ending .com without final slash',
      ) ?? getEnv('adminAppBaseURL')
    : getEnv('adminAppBaseURL'),
});

class APIClientMock {
  public CompanyClient = {
    getCompanyInfo: () => {
      return Promise.resolve({
        id: 'K-10269',
        name: 'DEMO COMPANY LTD.',
        companySize: 'No Creditcheck',
        address: null,
      });
    },

    updateCompanySettings: (payload: CompanySettings) => {
      return FakeStorage.setData<CompanySettings['settings']>('companySettings', payload.settings);
    },

    getCompanyDetails: () => {
      return Promise.resolve({
        created: '2020-07-30 10:21:27',
        settings: FakeStorage.getOrMock<CompanySettings['settings']>('companySettings', [
          {
            type: 'logo',
            value:
              'http://res.cloudinary.com/lendis-gmbh-staging/image/upload/v1664878171/lendis_logo.png',
          },
          { type: 'primaryColor', value: '#7de0b3' },
        ]),
        contact_date: '2019-02-22T00:00:00.000000Z',
        customer_size: 'SME',
        id: 'K-10269',
        customer_id: 'K-10269',
        name: 'DEMO COMPANY LTD.',
        office_type: 'Core office: HQ 123',
        lendis_id: 'K-10269',
        modified: '2022-12-01T14:01:41.696Z',
        entity: 'Company',
      });
    },

    getLogoSignedUrl: () => {
      console.error('Not Implemented!3');
      throw new Error('Not Implemented');
    },
  };
  public TaskClient = {
    updateTask: async (payload: TaskPayload) => {
      const currentTasks = FakeStorage.getOrMock<Invite[]>('algolia-tasks', [
        //here is not taskList as we use algolia
        {
          _id: '633ea7b03a15efd921fd4eeb',
          id: 'T-2022-00302',
          type: 'profile-invite',
          state: TASK_STATE.OPEN,
          customerId: 'K-10269',
          email: 'employee1@lendis.de',
          profileId: 'PROFILE-2022-913759',
          createdBy: 'employee@lendis.de',
          createdAt: '2022-10-06T10:02:24.876Z',
          modifiedAt: '2022-10-06T10:02:24.876Z',
          modifiedBy: 'employee1@lendis.de',
          userId: 'employee1@lendis.de',
        },
        {
          _id: '63353c1270fe0261602817ad',
          id: 'T-2022-00253',
          type: 'profile-invite',
          state: TASK_STATE.OPEN,
          customerId: 'K-10269',
          email: 'employee2@lendis.de',
          profileId: 'PROFILE-2022-2822',
          createdBy: 'employee@lendis.de',
          createdAt: '2022-09-29T06:32:50.520Z',
          modifiedAt: '2022-09-29T06:32:50.520Z',
          modifiedBy: 'employee2@lendis.de',
          userId: 'employee2@lendis.de',
        },
        {
          _id: '6328592cb823e5d585f99302',
          id: 'T-2022-00162',
          type: 'profile-invite',
          state: TASK_STATE.OPEN,
          customerId: 'K-10269',
          email: 'employee3@lendis.de',
          profileId: 'PROFILE-2022-2822',
          createdBy: 'employee@lendis.de',
          createdAt: '2022-09-19T11:57:32.684Z',
          modifiedAt: '2022-09-19T11:57:32.684Z',
          modifiedBy: 'employee3@lendis.de',
          userId: 'employee3@lendis.de',
        },
      ]);

      currentTasks.forEach((currentTask, taskIndex) => {
        if (currentTask.id === payload.id) {
          currentTasks[taskIndex] = {
            ...currentTask,
            ...payload,
          };
        }
      });

      //update Algolia
      if (payload.state === 'cancelled') {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const invitations = await useAlgolia('invitations').search('');
        invitations.hits.forEach((invitation, invitationIndex) => {
          if (invitation.objectID === payload.id) {
            invitations.hits[invitationIndex] = {
              ...invitations.hits[invitationIndex],
              task: {
                ...invitations.hits[invitationIndex].task,
                state: payload.state,
              },
            };
          }
        });
        FakeStorage.setData('algolia-invitations', invitations.hits);
      }

      FakeStorage.setData<Invite[]>('algolia-tasks', currentTasks);

      //sync on admin side
      domainPortal.exec('taskUpdate', payload);

      return currentTasks;
    },
    createBatchTask: async (payload: CreateBatchTaskPayload) => {
      const defaultTaskData = {
        id: 'T-2022-00162',
        type: 'profile-invite',
        state: TASK_STATE.OPEN,
        customerId: 'K-10269',
        email: 'employee3@lendis.de',
        profileId: 'PROFILE-2022-2822',
        createdBy: 'employee@lendis.de',
        createdAt: '2022-09-19T11:57:32.684Z',
        modifiedAt: '2022-09-19T11:57:32.684Z',
        modifiedBy: 'employee3@lendis.de',
      };

      const currentTasks = FakeStorage.getOrMock<Invite[]>('algolia-tasks', [
        {
          _id: '633ea7b03a15efd921fd4eeb',
          id: 'T-2022-00302',
          type: 'profile-invite',
          state: TASK_STATE.OPEN,
          customerId: 'K-10269',
          email: 'employee1@lendis.de',
          profileId: 'PROFILE-2022-913759',
          createdBy: 'employee@lendis.de',
          createdAt: '2022-10-06T10:02:24.876Z',
          modifiedAt: '2022-10-06T10:02:24.876Z',
          modifiedBy: 'employee1@lendis.de',
          userId: 'employee1@lendis.de',
        },
        {
          _id: '63353c1270fe0261602817ad',
          id: 'T-2022-00253',
          type: 'profile-invite',
          state: TASK_STATE.OPEN,
          customerId: 'K-10269',
          email: 'employee2@lendis.de',
          profileId: 'PROFILE-2022-2822',
          createdBy: 'employee@lendis.de',
          createdAt: '2022-09-29T06:32:50.520Z',
          modifiedAt: '2022-09-29T06:32:50.520Z',
          modifiedBy: 'employee2@lendis.de',
          userId: 'employee2@lendis.de',
        },
        {
          _id: '6328592cb823e5d585f99302',
          id: 'T-2022-00162',
          type: 'profile-invite',
          state: TASK_STATE.OPEN,
          customerId: 'K-10269',
          email: 'employee3@lendis.de',
          profileId: 'PROFILE-2022-2822',
          createdBy: 'employee@lendis.de',
          createdAt: '2022-09-19T11:57:32.684Z',
          modifiedAt: '2022-09-19T11:57:32.684Z',
          modifiedBy: 'employee3@lendis.de',
          userId: 'employee3@lendis.de',
        },
      ]);

      const profileData = await this.ProfileClient.getByProfileId(payload.profileId);
      const profileName = profileData.name;

      for (const email of payload.emails) {
        const newTaskId = 'T-' + new Date().getFullYear() + '-' + Math.floor(Math.random() * 10000);

        currentTasks.push({
          ...defaultTaskData,
          _id: `${Math.floor(Math.random() * 9999999999)}`.padEnd(24),
          id: newTaskId,
          email,
          userId: email,
          createdAt: new Date().toISOString(),
          modifiedAt: new Date().toISOString(),
        });

        //update Algolia
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const invitations = await useAlgolia('invitations').search('');
        invitations.hits.push({
          objectID: newTaskId,
          profile: { name: profileName },
          task: {
            createdAt: new Date().toISOString(),
            modifiedAt: new Date().toISOString(),
            email,
            id: newTaskId,
            state: TASK_STATE.OPEN,
          },
        });

        FakeStorage.setData('algolia-invitations', invitations.hits);

        profileData.stats.totalEmployees.push(newTaskId);
        this.ProfileClient.updateByProfileId(payload.profileId, {
          stats: { ...profileData.stats },
        });
      }

      FakeStorage.setData<Invite[]>('algolia-tasks', currentTasks);

      return Promise.resolve(currentTasks);
    },
    search: (request: Omit<SearchRequest, 'indexName'>): Promise<PaginatedResponse<Invite>> => {
      return Promise.resolve({
        data: FakeStorage.getOrMock<Invite[]>('algolia-tasks', [
          {
            _id: '633ea7b03a15efd921fd4eeb',
            id: 'T-2022-00302',
            type: 'profile-invite',
            state: TASK_STATE.OPEN,
            customerId: 'K-10269',
            email: 'employee1@lendis.de',
            profileId: 'PROFILE-2022-913759',
            createdBy: 'employee@lendis.de',
            createdAt: '2022-10-06T10:02:24.876Z',
            modifiedAt: '2022-10-06T10:02:24.876Z',
            modifiedBy: 'employee1@lendis.de',
            userId: 'employee1@lendis.de',
          },
          {
            _id: '63353c1270fe0261602817ad',
            id: 'T-2022-00253',
            type: 'profile-invite',
            state: TASK_STATE.OPEN,
            customerId: 'K-10269',
            email: 'employee2@lendis.de',
            profileId: 'PROFILE-2022-2822',
            createdBy: 'employee@lendis.de',
            createdAt: '2022-09-29T06:32:50.520Z',
            modifiedAt: '2022-09-29T06:32:50.520Z',
            modifiedBy: 'employee2@lendis.de',
            userId: 'employee2@lendis.de',
          },
          {
            _id: '6328592cb823e5d585f99302',
            id: 'T-2022-00162',
            type: 'profile-invite',
            state: TASK_STATE.OPEN,
            customerId: 'K-10269',
            email: 'employee3@lendis.de',
            profileId: 'PROFILE-2022-2822',
            createdBy: 'employee@lendis.de',
            createdAt: '2022-09-19T11:57:32.684Z',
            modifiedAt: '2022-09-19T11:57:32.684Z',
            modifiedBy: 'employee3@lendis.de',
            userId: 'employee3@lendis.de',
          },
        ]),
        totalItems: 3,
        hasMoreItems: false,
      });
    },
  };
  public ProfileClient = {
    getByProfileId: (id: string): Promise<ProfileResponse> => {
      const profiles = FakeStorage.getOrMock<Record<string, ProfileResponse>>(
        'profilesByID',
        profilesByID,
      );

      try {
        return Promise.resolve(profiles[id]);
      } catch (e) {
        throw new Error('Profile not found');
      }
    },
    createProfile: async (payload: ProfileRequest): Promise<{ profileId: string }> => {
      const newProfileId =
        'PROFILE-' + new Date().getFullYear() + '-' + Math.floor(Math.random() * 10000);
      const profiles = FakeStorage.getOrMock<Record<string, ProfileResponse>>(
        'profilesByID',
        profilesByID,
      );

      profiles[newProfileId] = {
        stats: {
          totalEmployees: [],
          activeInvites: [],
          inDeliveryOrders: [],
          fulfilledOrders: [],
        },
        profileId: newProfileId,
        isActive: true,
        createdAt: '2023-01-17T15:26:45.086Z',
        modifiedAt: '2023-01-17T15:26:45.086Z',
        products: [],
        rules: [],
        ...payload,
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const profilesAlgolia = await useAlgolia('profiles').search('');
      const { products, ...rest } = profiles[newProfileId];
      profilesAlgolia.hits.push({
        ...rest,
      });
      FakeStorage.setData('algolia-profiles', profilesAlgolia.hits);

      FakeStorage.setData<Record<string, ProfileResponse>>('profilesByID', profiles);

      return Promise.resolve(profiles[newProfileId]);
    },
    getProductsByProfileId: (id: string): Promise<ProfileProductsResponse> => {
      //seems not in use
      console.error('Not Implemented!6');
      throw new Error('Not Implemented');
    },
    updateByProfileId: async (id: string, payload: any): Promise<ProfileProductsResponse> => {
      const profiles = FakeStorage.getOrMock<Record<string, ProfileResponse>>(
        'profilesByID',
        profilesByID,
      );

      profiles[id] = {
        ...profiles[id],
        ...payload,
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const profilesAlgolia = await useAlgolia('profiles').search('');
      profilesAlgolia.hits.forEach((profile, profileIndex) => {
        if (profile.profileId === id) {
          const { products, ...rest } = profiles[id];
          profilesAlgolia.hits[profileIndex] = {
            ...profile,
            ...rest,
          };
        }
      });

      FakeStorage.setData('algolia-profiles', profilesAlgolia.hits);

      FakeStorage.setData<Record<string, ProfileResponse>>('profilesByID', profiles);

      return Promise.resolve(profiles[id].products);
    },
  };
  public CustomerSatisfactionClient = {
    userAnsweredForm: (userId: string): boolean => {
      return true;
    },
    createCustomerSatisfaction: (
      payload: CustomerSatisfactionRequest | CustomerSatisfactionSubmitRequest,
    ): Promise<boolean> => {
      console.error('Not Implemented!8');
      throw new Error('Not Implemented');
    },
  };
  public SettingClient = {
    uploadImage: (payload: UploadLogoPayload): Promise<string> => {
      console.error('Not Implemented!9');
      throw new Error('Not Implemented');
    },
  };
  public OrderClient = {
    createOrder: (payload: CreateOrderPayload) => {
      const newOrderId = 'O-' + new Date().getFullYear() + '-' + Math.floor(Math.random() * 10000);
      const orders = FakeStorage.getOrMock<Record<string, OrderPayload & { createdAt: string }>>(
        'ordersByID',
        {},
      );

      const items = payload.items.map((item, itemIndex) => {
        return {
          ...item,
          assetId: Math.floor(Math.random() * 10000)
            .toString()
            .padStart(13, '0'),
          state: 'New Reserved' as OrderLineItemV2['state'],
          id: newOrderId + itemIndex.toString().padStart(5, '0'),
          orderId: newOrderId,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
      });

      orders[newOrderId] = {
        id: newOrderId,
        state: OrderStates.OPEN,
        updatedAt: new Date().toISOString(),
        createdAt: new Date().toISOString(),
        ...payload,
        items,
      };

      FakeStorage.setData<Record<string, OrderPayload>>('ordersByID', orders);

      //sync on admin side
      domainPortal.exec('createOrderOnAlgolia', orders[newOrderId]);

      return Promise.resolve(orders[newOrderId]);
    },
    getOrder: (orderId: string): Promise<OrderPayload> => {
      const orders = FakeStorage.getOrMock<Record<string, OrderPayload>>('ordersByID', {});

      try {
        return Promise.resolve(orders[orderId]);
      } catch (e) {
        throw Error('Order not found');
      }
    },
    getCurrentEmployeeOrders: async (orderIds: string[]): Promise<OrderPayload[]> => {
      return Promise.all(orderIds.map((orderId) => this.OrderClient.getOrder(orderId)));
    },
  };
  public SupportTicketClient = {
    getSupportTicketInfo: async (ticketID: number): Promise<GetSupportTicketInfoResponse> => {
      return Promise.resolve({
        ticket: { id: '1', status: 'new', description: '', custom_fields: [] },
      });
    },
    createSupportTicket: async (
      payload: SupportTicketPayload,
    ): Promise<CreateSupportTicketResponse> => {
      return Promise.resolve({
        audit: {},
        ticket: { id: '1', status: 'new', description: '', custom_fields: [] },
      });
    },
  };
  public EmployeeClient = {
    getEmployee: async (employeeId: string): Promise<ActiveEmployeesAggregation> => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const invites = await useAlgolia('tasks').search('', { filters: `userId:${employeeId}` });

      const profiles = await Promise.all(
        invites.hits.map((invite) => this.ProfileClient.getByProfileId(invite.profileId)),
      );

      const filteredOrders: OrderType[] = [];
      const orders = FakeStorage.getOrMock<Record<string, OrderPayload>>('ordersByID', {});
      Object.entries(orders).forEach((order) => {
        const [orderId, orderData] = order;
        if (orderData.userId === employeeId) {
          filteredOrders.push({
            id: orderId,
            state: orderData.state,
            updatedAt: orderData.updatedAt,
            delivery: orderData.delivery,
          } as OrderType);
        }
      });

      return {
        _id: employeeId,
        user: {
          id: employeeId,
          userId: employeeId,
          firstname: 'Mocked',
          lastname: 'Mocked',
          email: employeeId,
        },
        employeeId: employeeId,
        customerId: 'K-10269',
        invites: invites.hits,
        profiles: profiles,
        orders: filteredOrders,
        assets: [],
        createdAt: '2022-11-03T13:39:36.283Z',
        modifiedAt: '2023-01-20T13:43:22.104Z',
      };
    },
  };
  public UserOrderClient = {
    getOrder: (orderId: string): Promise<any> => {
      return this.OrderClient.getOrder(orderId);
    },
  };
  public TaxClient = {
    getTaxRates: async () => {
      return Promise.resolve({
        DE: {
          code: 'DE',
          taxRate: 19,
        },
      });
    },
  };
}

export default new APIClientMock();
