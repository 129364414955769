import React from 'react';
import { GoogleIcon } from './Icons/Google';
import { useAuth } from '@platform/auth';
import { TFunction } from 'react-i18next';

export const SocialSignOn = ({
  isSignup,
  t,
}: {
  isSignup?: boolean;
  t: TFunction<'translation', undefined>;
}) => {
  const auth = useAuth();
  const buttonText = isSignup ? t('googleButton.signUp') : t('googleButton.signIn');

  return (
    <div className="mt-4 mx-2">
      <div className="relative flex py-3 items-center">
        <hr className="flex-grow bg-gray-300" />
        <span className="flex-shrink px-2 text-gray-500 text-sm">OR</span>
        <hr className="flex-grow bg-gray-300" />
      </div>

      <div className="mt-4 grid justify-items-center">
        <div className="w-2/3">
          <button
            data-testid="google-button"
            onClick={auth?.googleSocialSSO}
            className="cursor-pointer inline-flex w-full justify-center rounded-md border border-gray-200 bg-white py-2 px-4 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
          >
            <span className="sr-only">{buttonText}</span>
            <GoogleIcon />
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
};
