import { useAuth } from '@platform/auth';
import { useQuery } from 'react-query';
import API from '../api';

export const useCompanyDetails = () => {
  const { user, isAuthenticated } = useAuth();
  const customerId = user && user.id ? user.attributes['custom:customer_id']! : '';

  const {
    data: companyDetails,
    isLoading,
    isError,
  } = useQuery(`company-details-${customerId}`, async () => API.CompanyClient.getCompanyDetails(), {
    enabled: isAuthenticated && user !== null,
    suspense: true,
    useErrorBoundary: false,
  });

  return {
    companyDetails: isError ? null : companyDetails ?? null,
    isLoading,
    isError,
  };
};
